.auth_page {
	display: flex;
	flex-wrap: wrap;
	min-height: 100vh;
	.auth_form {
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px;
		position: relative;
		margin-left: 50%;
		@media (max-width: 767px) {
			width: 100%;
			margin-left: 0;
		}
		.auth_form_wrapper {
			padding-bottom: 0;
			width: 500px;
			@media (max-width: 767px) {
				width: 100%;
			}
			.left_arrow {
				position: absolute;
				left: -32px;
				margin-top: -10px;
				img {
					max-height: 61px;
				}
			}
			h2 {
				font-weight: 600;
				font-size: 32px;
				line-height: 38px;
				color: var(--mostly-black);
				margin-bottom: 20px;
				@media (max-width: 991px) {
					font-size: 26px;
					line-height: 30px;
					margin-bottom: 10px;
				}
			}
			p {
				font-weight: 500;
				font-size: 18px;
				line-height: 25px;
				color: var(--black);
				margin-bottom: 46px;
				@media (max-width: 991px) {
					font-size: 16px;
					line-height: 23px;
					margin-bottom: 26px;
				}
			}
			.main-form-floating {
				margin-bottom: 30px;
				.error {
					label {
						color: var(--soft-red);
					}
					span.info-icon {
						svg {
							fill: var(--soft-red);
							path {
								fill: var(--soft-red);
							}
						}
					}
				}
				span.icon {
					position: absolute;
					top: 20px;
					bottom: 0;
					left: 25px;
					@media (max-width: 991px) {
						top: 14px;
					}
					img {
						max-width: 20px;
						@media (max-width: 991px) {
							max-width: 16px;
						}
					}
				}
				span.eye-icon {
					position: absolute;
					top: 20px;
					bottom: 0;
					right: 31px;
					cursor: pointer;
					img {
						max-width: 18px;
					}
				}
			}
			input {
				height: 65px;
				background: #f7f7f7;
				border-radius: 10px;
				border: none;
				font-weight: 500;
				font-size: 18px;
				line-height: 21px;
				color: var(--mostly-black);
				padding: 20px 60px 0 60px;
				@media (max-width: 991px) {
					height: 50px;
					font-size: 16px;
					line-height: 19px;
				}
				&:focus {
					outline: none;
					box-shadow: none;
				}
			}
			label {
				font-weight: 400;
				font-size: 18px;
				line-height: 21px;
				color: var(--mostly-black);
				padding: 0 40px 0 60px;
				display: flex;
				align-items: center;
				@media (max-width: 991px) {
					font-size: 16px;
					line-height: 19px;
				}
			}
			.btn {
				background: var(--very-light-blue);
				box-shadow: 0px 7px 12px rgba(89, 158, 255, 0.5);
				border-radius: 10px;
				border: none;
				height: 65px;
				width: 100%;
				font-weight: 600;
				font-size: 22px;
				line-height: 26px;
				color: var(--white);
				transition: all 0.5s ease;
				transform: translateY(0);
				margin-top: 20px;
				margin-bottom: 15px;
				@media (max-width: 991px) {
					height: 50px;
					font-size: 20px;
					line-height: 24px;
					margin-top: 15px;
					margin-bottom: 10px;
				}
				&:hover {
					transform: translateY(-3px);
				}
			}
			.main-form-floating {
				margin-bottom: 30px;
				@media (max-width: 991px) {
					margin-bottom: 15px;
				}
			}
			span.forgot {
				display: block;
				text-align: center;
				font-weight: 500;
				font-size: 18px;
				line-height: 21px;
				color: var(--mostly-black);
				margin: 15px 0 73px;
				@media (max-width: 991px) {
					font-size: 16px;
					line-height: 19px;
					margin: 15px 0 27px;
				}
				@media (max-width: 767px) {
					margin: 15px 0;
				}
				a {
					text-decoration: none;
					color: var(--very-light-blue);
					&:hover {
						text-decoration: underline;
					}
				}
			}
			.success {
				color: var(--strong-cyan);
			}
			.fail {
				color: var(--soft-red);
			}
		}
		span.signup {
			display: block;
			text-align: center;
			width: 100%;
			font-weight: 500;
			font-size: 18px;
			line-height: 21px;
			color: var(--black);
			@media (max-width: 991px) {
				font-size: 16px;
				line-height: 19px;
			}
			b {
				color: var(--very-light-blue);
				font-weight: 600;
			}
			a {
				text-decoration: none;
				color: var(--very-light-blue);
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
.auth_page
	.auth_form
	.auth_form_wrapper
	.form-floating
	> .form-control:not(:placeholder-shown)
	~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
	font-size: 14px;
	line-height: 16px;
	transform: scale(0.85) translateY(-0.6rem) translateX(10px) !important;
	opacity: 1 !important;
	&:after {
		background-color: transparent !important;
	}
}
