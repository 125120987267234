.settings_section {
  margin-top: 80px;
  padding-bottom: 20px;
}

p.receive-mail {
  color: #ca82ff !important;
}

.notify-items {
  margin-left: 10px;
}

.security_section {
  padding-top: 0px;
  .security_wrapper {
    h2 {
      margin: 0;
      font-size: 28px;
      padding-bottom: 0px;
      margin-bottom: 20px;
      @media (max-width: 767px) {
        font-size: 22px;
      }
      span {
        color: var(--very-light-orange);
      }
    }
    a {
      color: var(--purple);
      text-decoration: none;
      font-size: 18px;
      font-weight: 500;

      &:hover {
        color: var(--very-light-blue);
      }
    }
    p {
      font-size: 16px;
      margin: 10px 0 0;
      color: var(--mostly-black);
    }
  }
}
.setting {
  padding: 7rem 0;
}
.change-password {
  background-color: #ca82ff !important;
  color: #fff !important;
  padding: 13px 11px;
  border-radius: 10px;
  font-size: 16px;
  text-transform: capitalize;
}
.change-password:hover {
  background-color: #000 !important;
}
.top-setting p {
  margin-top: 14px !important;
}
.hr-clr {
  border: 1px solid gray;
  margin: 24px 0;
}
