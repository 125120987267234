.footer-btn {
  display: flex;
  justify-content: space-between !important;
}

img.arrow-img-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-top: 5px;
}

img.arrow-img-icon {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
}

img.arrow-img-icon.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.resize-none {
  resize: none !important;
}
