.max-w-400 {
  max-width: 400px !important;
}
.max-w-600 {
  max-width: 600px !important;
}
.max-w-700 {
  max-width: 700px !important;
}
.max-w-800 {
  max-width: 800px !important;
}
  .title{
    display: flex-start;
    font-size: 18px;
    font-weight: bold;
  }
  .text-blue {color: #82b6ff !important;}
  body .btn-primary {
    background-color: #ca82ff;
    color: #fff;
  }
  .contact_form_group {
    margin-bottom: 10px;
  }
  
  .contact_form_group_item {
    display: inline-block;
  }
  
  .input_field{
    width: 440px;
    height: 80px;
      background-color: #f7f7f7 !important;
      border: none !important;
      border-radius: 10px !important;
  }
  

  .error{
    border: 1px solid red
  }
  .error-message{
    color: red
  }
  body .field-with-icon {
    display: block;
    padding: 20px 20px 10px 55px;
    background-color: #f7f7f7;
    border: none;
    border-radius: 10px !important;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    background-repeat: no-repeat;
    background-position: 15px 25px;
    background-size: 25px 15px;
}
.text-orange {
  color: var(--very-light-orange) !important;
}
.btn.cs-btn-lg {
  padding: 20px;
  font-size: 1.2rem;
  font-weight: 600 !important;
  height: auto !important;
  line-height: 1;
  border: 0;
  min-width: 240px;
  text-align: center;
}
.text-orange {
  color: rgb(255, 150, 104) !important;
}
.cs-h2 {
  font-size: 32px;
  line-height: normal;
  margin: 0 0 20px 0;
  font-weight: 600;
}
textarea.field-with-icon {
  background-image: url(../../assets/images/icons/message-square.svg);
}
.font-26px {
  font-size: 26px !important;
}
.font-16px {
  font: 16px !important;
}
.bg-title {
  background: url(../../assets/images/bg-title.png),
    linear-gradient(
      180deg,
      rgba(255, 150, 104, 0.1) 0%,
      rgba(130, 182, 255, 0.1) 100%
    );
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.button-group {
  margin-top: 10px;

  // button {
  //   margin-right: 10px;
  // }

  .form-btn {
    height: 50px;
    border-radius: 10px;
  }
  span {
    margin: 0px 5px;
  }

  button:last-child {
    margin-right: 0;
  }
}

/* Container for the floating label */
.floating-label textarea {
  padding: 24px 20px 10px 55px;
}
.floating-label {
  position: relative;
  margin-top: 1.5rem;
}

/* Textarea styles */
.floating-label textarea {
  width: 100%;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
}

/* Default label styles */
.floating-label label {
  position: absolute;
  top: 1rem;
  left: 55px;
  pointer-events: none;
  transition: all 0.2s ease-out;
  color: #000;
  font-size: 1rem;
}

/* Label moves up when the textarea is focused */
.floating-label textarea:focus + label,
.floating-label textarea:not(:placeholder-shown) + label {
  transform: scale(0.9) translate(-10px, -12px);
}

/* Error styles */
.floating-label textarea.error {
  border-color: #ff6b6b;
}

.error-message {
  color: #ff6b6b;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

// contact page css here belOW
.contact_page {
  .banner_section {
    background-image: url("../../assets/images/banner_contact.jpg");
    background-size: cover;
    padding: 5.438rem 0;
    position: relative;
    text-align: center;

    &:before {
      content: "";
      background: var(--very-light-orange);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.9;
    }

    h1 {
      font-weight: 700;
      font-size: 30px;
      line-height: 1;
      color: #fff;
      margin: 0;
      position: relative;

      @media (min-width: 1200px) {
        font-size: 3rem;
        line-height: 4.75rem;
      }

      @media (min-width: 992px) and (max-width: 1199px) {
        font-size: 3rem;
        line-height: 4.25rem;
      }
    }

    p {
      font-size: 24px;
      color: var(--white);
      margin: 0;
      position: relative;

      @media (max-width: 991px) {
        font-size: 18px;
      }
    }
  }

  h2 {
    font-size: 32px;
    line-height: normal;
    margin: 0 0 20px 0;
    font-weight: 600;

    @media (max-width: 991px) {
      font-size: 26px;
      margin: 0 0 15px 0;
    }
  }

  p,
  ul {
    font-size: 20px;
    color: #000;
    margin: 0;

    @media (max-width: 991px) {
      font-size: 16px;
    }
  }

  .contact_banner {
    img {
      margin-top: 50px;
      width: 95%;

      @media (max-width: 991px) {
        width: 100%;
      }
    }

    p {
      margin-bottom: 10px;

      a {
        color: var(--purple);
        text-decoration: none;

        &:hover {
          color: var(--vivid-blue);
        }
      }
    }
  }

  .contact_form_section {
    h2 {
      margin-bottom: 28px;
    }

    .contact_form {
      margin-bottom: 8px;

      .contact_form_group_item {
        position: relative;
        margin-bottom: 30px;
      }

      .error_message {
        font-size: 14px;
        margin-top: 5px;
      }

      .input_field {
        display: block;
        width: 100%;
        padding: 24px 20px 10px 55px;
        font-size: 16px;
        background: var(--white);
        background-color: #f7f7f7;
        border: none;
        border-radius: 10px;
        background-repeat: no-repeat;
        background-position: 15px 20px;
        background-size: 25px 15px;
        font-weight: 500;
        position: relative;
        &:focus + label {
          transform: scale(0.9) translate(-5px, -12px);
        }

        &::placeholder {
          color: #f7f7f7;
        }

        &:not(:placeholder-shown) + label {
          transform: scale(0.9) translate(-5px, -12px);
        }

        @media (max-width: 991px) {
          font-size: 16px;
        }

        &.user-field {
          background-image: url("../../assets/images/icons/user-squre.svg");
        }

        &.email-field {
          background-image: url("../../assets/images/icons/email-squre.svg");
        }
      }

      .contact_form_group {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        p {
          margin-top: -15px;
          margin-bottom: 15px;
          font-size: 14px;
          margin-left: 20px;

          @media (max-width: 991px) {
            margin-top: 10px;
          }
        }

        .contact_form_group_item {
          width: calc(50% - 10px);

          @media (max-width: 991px) {
            width: 100%;
          }
        }

        @media (max-width: 991px) {
          flex-wrap: wrap;
        }
      }

      .contact_form_select {
        margin-bottom: 30px;
        z-index: 12;
        position: relative;

        .contact_form_select_input {
          background-image: url("../../assets/images/icons/files-squre.svg");
          background-repeat: no-repeat;
          background-position: 15px 20px;
          background-size: 25px 15px;
          padding: 6px 0;
          background-color: #f7f7f7;
          border: none;
          border-radius: 10px;
          position: relative;
          label {
            font-size: 14px;
            color: #000;
            display: block;
            position: absolute;
            top: 18px;
            margin-bottom: 2px;
            left: 55px;
            transition-duration: 0.4s;
            pointer-events: none;
          }
          &.contact_form_select_focus {
            label {
              transform: scale(0.9) translate(-5px, -12px);
            }
          }
          .wrapper {
            border-radius: 0;
            max-width: calc(100% - 55px);
            margin-left: auto;
            display: block;
            position: relative;
            background-color: transparent;
            border: none;

            &:hover,
            &:focus-within {
              box-shadow: none;
            }

            .line {
              border: none;
            }

            ul {
              background-color: #fff;
            }

            input {
              font-size: 16px;
              font-weight: 500;
              padding: 16px 0 0;
              font-family: "Work Sans", sans-serif;
            }
          }
        }
      }

      .input_label {
        font-size: 14px;
        color: #000;
        display: block;
        position: absolute;
        top: 18px;
        margin-bottom: 2px;
        left: 55px;
        transition-duration: 0.4s;
        pointer-events: none;

        span {
          color: red;
        }

        @media (max-width: 991px) {
          font-size: 16px;
        }
      }
      textarea {
        display: block;
        padding: 24px 20px 10px 55px;
        background-color: #f7f7f7;
        border: none;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 500;
        width: 100%;
        background-repeat: no-repeat;
        background-position: 15px 20px;
        background-size: 25px 15px;

        &.message-field {
          background-image: url("../../assets/images/icons/message-square.svg");
        }

        &:focus + label {
          transform: scale(0.9) translate(-5px, -12px);
        }

        &::placeholder {
          color: #f7f7f7;
        }

        &:not(:placeholder-shown) + label {
          transform: scale(0.9) translate(-5px, -12px);
        }

        @media (max-width: 991px) {
          font-size: 16px;
        }
      }

      .primary-btn {
        text-align: left;

        button.btn {
          background-color: var(--purple);
          color: white;
          width: 240px;
          margin-top: 20px;
          padding: 20px;
          border: none;
          line-height: 1;
          border-radius: 10px;

          &:hover {
            background-color: var(--black);
          }

          @media (max-width: 991px) {
            font-size: 16px;
            width: 210px;
          }

          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }
    }
  }

  .town_section {
    h2 {
      margin-bottom: 12px;
      text-align: center;
    }

    iframe {
      width: 100%;
      height: 370px;
      border: none;
    }

    .town_section_wrapper {
      position: relative;
      text-align: center;

      .map_notice {
        padding-bottom: 30px;

        a {
          color: var(--purple);
          text-decoration: none;

          &:hover {
            color: var(--vivid-blue);
          }
        }
      }
    }
  }

  .contact_page_container {
    min-height: calc(100vh - 259px);
  }
}

@media (min-width: 768px) {
  .contact_form_banner_section {
    display: flex;
    flex-wrap: wrap;
    padding: 60px 0 40px;
    margin-bottom: 40px;
  }

  .contact_form_banner_section .contact_banner {
    padding-right: 20px;
    width: 50%;
    padding-top: 10px;
  }

  .contact_form_banner_section .contact_form_wrapper {
    padding-left: 20px;
    width: 50%;
    padding-top: 10px;
  }
}

@media (max-width: 767px) {
  .contact_form_banner_section {
    padding: 60px 0 20px;
    margin-bottom: 20px;
  }

  .contact_form_banner_section .contact_banner {
    padding: 0px 0 20px;
    margin-bottom: 20px;
  }
}

.ellipsis {
  display: none !important;
}

.blur-readonly input,
.blur-readonly *,
.blur-readonly label {
  color: #afaeae !important;
}
