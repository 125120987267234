.home_page {
    min-height: calc(100vh - 46px);
    .banner_section {
        height: 100vh;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url('../../assets/images/home-banner-bg2.jpg');
        background-size: cover;
        margin-bottom: 40px;
        @media (max-width: 1199px) {
            min-height: 650px;
        }
        @media (max-width: 767px) {
            padding-top: 90px;
            padding-bottom: 40px;
            height: auto;
        }
        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            background: linear-gradient(45deg, rgba(255, 150, 104, 0.9), rgba(202, 130, 255, 0.9));
        }
        .down-arrow {
            position: absolute;
            bottom: 90px;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 2;
            width: 40px;
            height: 70px;
            @media (max-width: 767px) {
                display: none;
            }
            span {
                position: absolute;
                top: 0;
                left: 50%;
                width: 24px;
                height: 24px;
                margin-left: -12px;
                border-left: 2px solid #fff;
                border-bottom: 2px solid #fff;
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
                -webkit-animation: sdb07 2s infinite;
                animation: sdb07 2s infinite;
                opacity: 0;
                box-sizing: border-box;
                &:nth-of-type(1) {
                    -webkit-animation-delay: 0s;
                    animation-delay: 0s;
                }
                &:nth-of-type(2) {
                    top: 16px;
                    -webkit-animation-delay: .15s;
                    animation-delay: .15s;
                }
                &:nth-of-type(3) {
                    top: 32px;
                    -webkit-animation-delay: .3s;
                    animation-delay: .3s;
                }
            }		  
        }
        .banner_bottom {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            transform: rotate(180deg);
            z-index: 1;
            svg {
                height: 80px;
                width: 100%;
                vertical-align: top;
                @media (max-width: 767px) {
                    height: 40px;
                }
                .exqute-fill-white {
                    fill: var(--white);
                }
            }
        }
        .banner_wrapper {
            z-index: 12;
            // position: relative;
            text-align: center;
            margin: 0 auto 30px;
            width: 100%;
            // height: 100%;

            .btn {
                margin-top: 40px;
                @media (max-width: 767px) {
                    padding: 6px 30px;
                    font-size: 16px;
                    font-weight: 500;
                }
            }

            .video-btn {
                margin-right: 14px;
                color: #fff;
                border-color: #fff;
                background: transparent;
                svg {
                    margin-right: 10px;
                }
            }
            .video-btn:hover {
                color: #fff;
                border-color: #FF9668;
                background-color: #FF9668;
                text-decoration: none;
            }

            .practice-btn {
                background: var(--very-light-orange);
                border-color: var(--very-light-orange);
                color: var(--white);
            }
            .practice-btn:hover {
                text-decoration: none;
                border-color: var(--white);
                background: transparent;
                color: var(--white);
            }

            h1 {
                color: var(--green);
                font-size: 48px;
                font-weight: 600;
                text-shadow: none;
                letter-spacing: 1px;
                margin-bottom: 25px;
                text-align: center;
                @media (max-width: 1199px) {
                    font-size: 38px;
                }
                @media (max-width: 767px) {
                    font-size: 28px;
                }
                span {
                    display: block;
                }
            }
            .home-slider {
                // height: 100%;
                position: unset;
                .slick-list {
                    height: 100% !important;
                    .slick-track {
                        display: flex;
                        align-items: center;
                        height: 100%;
                        .slick-slide {
                            > div {
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }
                }
                .slick-slide img {
                    margin: auto;
                    width: 50%;
                    // opacity: 0.8;
                }
                .slick-slide h2 {
                    margin: auto;
                    margin-top: 14px;
                    margin-bottom: 14px;
                    font-weight: 400;
                    font-size: 34px;
                    color: white;
                    // -webkit-text-stroke: 1px #F8F8F8;
                    // text-shadow: 0px 2px 4px blue;
                }
                .slide_item {
                    .slide_content {
                        // max-width: 820px;
                        // margin: auto;
                        @media (max-width: 1199px) {
                            max-width: 640px;
                        }
                        @media (max-width: 1023px) {
                            max-width: 100%;
                            padding: 0 15px;
                        }
                    }
                    .slide_content_text {
                        p {
                            color: var(--white);
                            font-size: 20px;
                            line-height: 1.4;
                            text-align: center;
                            font-weight: 400;
                            margin: 0;
                            @media (max-width: 767px) {
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }
                        input {
                            border: none;
                            box-shadow: none;
                            outline: none;
                            border-bottom: 2px solid var(--gray);
                            background: transparent;
                            width: 130px;
                            color: var(--gray);
                        }
                        span {
                            display: flex;
                            margin-top: 30px;
                            font-size: 16px;
                            color: var(--gray);
                            text-align: center;
                            justify-content: center;
                            @media (max-width: 767px) {
                                margin-top: 15px;
                                font-size: 16px;
                            }
                        }
                    }
            
                    .radio-button {
                        text-align: center;
                        margin-top: 20px;
                        .radio-btn-column {
                            position: relative;
                            margin-right: 25px;
                            display: inline-block;
                            input {
                                position: absolute;
                                z-index: 9;
                                left: 0;
                                right: 0;
                                opacity: 0;
                                width: 100%;
                                height: 19px;
                                &:checked {
                                    + label {
                                        background: var(--gray);
                                        color: #CA82FF;
                                    }
                                }
                            }
                            label {
                                background: #CA82FF;
                                border-radius: 5px;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 19px;
                                color: #f3f3f3;
                                padding: 2px 10px;
                                margin-bottom: 10px;
                            }
                        }
                    }
                    .svg_item {
                        max-width: 400px;
                        margin: 0 auto;
                        @media (max-width: 767px) {
                            max-width: 200px !important;
                        }
                    }
                    .slider_svg {
                        max-width: 400px;
                        svg {
                            width: 150%;
                            height: auto;
                            path {
                                fill: var(--gray) !important;
                            }
                            circle {
                                fill: var(--gray) !important;
                            }
                        }
                    }
                    .content_with_svg {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        @media (max-width: 767px) {
                            display: block;
                        }
                        .slide_content_text {
                            width: 50%;
                            padding-right: 15px;
                            @media (max-width: 767px) {
                                width: 100%;
                                padding-right: 0;
                            }
                            P {
                                text-align: left;
                                @media (max-width: 767px) {
                                    text-align: center;
                                    margin-bottom: 10px;
                                }
                            }
                        }
                        .svg_icon {
                            width: 50%;
                            display: flex;
                            justify-content: flex-end;
                            @media (max-width: 767px) {
                                width: 100%;
                                margin: 20px auto 0;
                                max-width: 220px !important;
                            }
                        }
                    }
                }
                .slick-arrow {
                    width: auto;
                    height: auto;
                    top: auto;
                    bottom: 60px;
                    @media (max-width: 767px) {
                        display: none !important;
                    }
                    &:before {
                        content: "";
                        opacity: 1;
                        height: 30px;
                        background-size: contain;
                        display: block;
                        background-repeat: no-repeat;
                        width: 30px;
                        background-position: center;
                        line-height: normal;
                        @media (max-width: 767px) {
                            height: 24px;
                            width: 24px;
                        }
                    }
                    &.slick-prev {
                        left: auto;
                        right: 60px;
                        &:before {
                            background-image: url(../../assets/images/prev-white.png);
                        }
                    }
                    &.slick-next {
                        right: 20px;
                        &:before {
                            background-image: url(../../assets/images/next-white.png);
                        }
                    }
                }
                .slick-dots {
                    right: 0;
                    bottom: auto;
                    top: 50%;
                    transform: translateY(-50%);
                    display: flex !important;
                    justify-content: flex-end;
                    flex-wrap: wrap;
                    max-width: 250px;
                    @media (max-width: 1023px) {
                        max-width: 700px;
                        top: auto;
                        bottom: 110px;
                        justify-content: center;
                        left: 0;
                        margin: auto;
                    }
                    @media (max-width: 767px){
                        position: relative;
                        bottom: 0;
                        transform: translateY(0%);
                        margin-top: 20px;
                    }
                    li { 
                        height: auto;
                        width: 100%;
                        text-align: right;
                        margin: 0;
                        font-size: 14px;
                        color: var(--white);
                        font-weight: 400;
                        padding-right: 30px;
                        transition: all 0.5s ease;
                        margin-bottom: 15px;
                        @media (max-width: 1023px) {
                            width: auto;
                            margin-bottom: 0;
                            padding: 10px;
                        }
                        @media (max-width: 767px){
                            padding: 5px;
                        }
                        &:before {
                            content: "";
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            background-color: #fff;
                            width: 20px;
                            height: 1px;
                            transition: all 0.5s ease;
                            @media (max-width: 1023px) {
                                width: 1px;
                                height: 20px;
                            }
                        }
                        &.slick-active {
                            color: var(--green);
                            padding-right: 50px;
                            font-weight: 600;
                            font-size: 16px;
                            @media (max-width: 1023px) {
                                padding: 10px;
                            }
                            @media (max-width: 767px){
                                padding: 5px;
                                font-weight: 400;
                                font-size: 14px;
                            }
                            &:before {
                                background-color: var(--green);
                                width: 40px;
                                @media (max-width: 1023px) {
                                    background-color: #fff;
                                    width: 1px;
                                }
                            }
                        }
                        &:nth-child(5) {
                            &:before {
                                @media (min-width: 768px) and (max-width: 1023px) {
                                    display: none;
                                }
                            }
                        }
                        &:last-child {
                            &:before {
                                @media (max-width: 1023px) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.video-section {
    padding: 40px 0;
    .video-section_wrapper {
        h2 {
            text-align: center;
            font-size: 38px;
            font-weight: 600;
            margin-bottom: 16px;
            line-height: 1.4;
        }
        .video-container {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
        }
    }
    .video-responsive {
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        height: 0;
      }
      
      .video-responsive iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
      }
    
}

.access_to_practice {
    padding: 60px 0px;
    h2 {
        margin-bottom: 40px;
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        text-align: center;
        color: #090E11;
        @media(max-width:767px){
            font-size: 24px;
            line-height: 1.2;
            margin-bottom: 20px;
        }
    }
    .access_to_practice_wrapper{
        display:flex;
        justify-content:space-between;
        @media(max-width:767px){
            flex-direction: column;
        }

        .access_to_practice_item {
            width:calc(33% - 30px);
            @media(max-width:1199px){
                width:calc(33% - 20px);
            }
            @media(max-width:991px){
                width:calc(33% - 10px);
            }
            @media(max-width:767px){
                width:100%;
                &:not(:last-child){
                    margin-bottom: 30px;
                }
            }
            .access_to_practice_box{
                background: var(--white);
                box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
                border-radius: 10px;
                overflow:hidden;
                position: relative;
               
                &.public .access_to_practice_title{
                    background-color: var(--very-light-orange);
                    color: var(--very-light-orange);
                }
                &.basic .access_to_practice_title{
                    background-color: var(--purple);
                    color: var(--purple);
                }
                &.premium .access_to_practice_title{
                    background-color: var(--very-light-blue);
                    color: var(--very-light-blue);
                }
                .access_to_practice_title{
                    position: relative;
                    padding:35px 0px;
                    @media (max-width: 991px) {
                        padding: 16px 0px;
                    }
                    &::after{
                        content:'';
                        display: block;
                        width:35px;
                        height: 28px;
                        position: absolute;
                        left: 50%;
                        bottom: -28px;
                        transform: translateX(-50%);
                        clip-path: polygon(50% 100%, 0 0, 100% 0);
                        background-color: currentColor;
                        @media(max-width:1199px){
                            bottom: -22px;
                        }
                    }
                    h4{
                        text-align:center;
                        color:var(--white);
                        margin: 0;
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 26px;
                        @media (max-width: 991px) {
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 1;
                        }
                    }
                }
                .access_to_practice_text{
                    padding: 55px 40px 25px;
                    @media(max-width:1199px){
                        padding: 35px 20px 15px;
                    }
                    ul {
                        padding: 0px;
                        margin: 0;
                        list-style: none;
                        li{
                            font-size: 18px;
                            display: flex;
                            align-items: flex-start;
                            padding: 8px 0px;
                            font-weight: 500;
                            @media(max-width:991px){
                                font-size: 14px;
                            }
                            &:before{
                                content: '';
                                width:30px;
                                height:30px;
                                display: inline-block;
                                list-style: none;
                                background-image: url('../../assets/images/icons/unavailible-icon.svg');
                                background-repeat: no-repeat;
                                background-position: center center;
                                margin-right: 20px;
                                @media(max-width:1199px){
                                    margin-right: 10px;
                                }
                                @media(max-width:991px){
                                    width: 20px;
                                    height: 16px;
                                    background-position: bottom;
                                    background-size: 11px;
                                }
                            }
                            &.chack::before{
                                background-image: url('../../assets/images/icons/check-icon.svg');
                                @media (max-width: 991px) {
                                    background-size: 14px;
                                }
                            }
                        }
                    }
                }    
            }
            .btn {
                margin-top: 40px;
                font-size: 1.2rem;
                font-weight: 600;
                border-radius: 30px;
                padding: 10px 40px;
                position: relative;
                min-width: max-content;
                margin-bottom: 10px;
                width: 100%;
                @media(max-width:1199px){
                    margin-top: 20px;
                }
                @media(max-width:991px){
                    margin-top: 10px;
                }
                &.btn_purple{
                    background-color:var(--purple);
                    color:var(--white);                
                    right: 0;
                    &:hover{
                        background-color:var(--very-light-orange);
                    }
                }
                &.see_pricing{
                    background-color:transparent;
                    border:solid 1px currentColor;
                    color:var(--very-light-blue);
                    top:0;
                    right: 0;
                    @media(max-width:767px){
                        position: relative;
                    }
                    &:hover{
                        color:var(--very-light-orange)
                    }
                }
            } 
            span {
                display: inline-block;
                width: 100%;
                text-align: center;
            }
        }
    }
}

.services_section {
    padding: 80px 0;
    @media (max-width: 767px) {
        padding: 40px 0;
    }
    .services_wrapper {
        h3 {
            text-align: center;
            font-size: 22px;
            line-height: 1;
            color: #ff9668;
            font-weight: 500;
            margin-bottom: 0;
            @media (max-width: 767px) {
                font-size: 18px;
            }
        }
        h2 {
            text-align: center;
            font-size: 38px;
            font-weight: 600;
            margin-bottom: 16px;
            line-height: 1.4;
            @media (max-width: 767px) {
                font-size: 28px;
                margin-bottom: 12px;
            }
        }
        p {
            font-size: 16px;
            line-height: 1.2;
            text-align: center;
            max-width: 510px;
            margin: auto;
            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
        .services_list {
            display: flex;
            flex-wrap: wrap;
            margin: 40px -15px 0;
            @media (max-width: 1023px) {
                margin-left: -10px;
                margin-right: -10px;
            }
            .service_box {
                width: 25%;
                padding: 0 15px;
                @media (max-width: 1023px) {
                    padding: 0 10px;
                }
                @media (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 20px;
                }
                .service_box_inner {
                    box-shadow: 0 4px 6px -1px  rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
                    border-radius: 20px;
                    padding: 20px;
                    height: 100%;
                    transition: all 0.5s ease;
                    @media (max-width: 1023px) {
                        padding: 10px;
                    }
                    @media (max-width: 767px) {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 10%);
                    }
                }
                .service_icon {
                    width: 70px;
                    height: 70px;
                    // border-radius: 1rem;
                    // background-color: #ff9668;
                    // padding: 15px;
                    margin: auto;
                    text-align: center;
                    @media (max-width: 1023px) {
                        width: 40px;
                        height: 40px;
                        padding: 8px;
                    }
                    @media (max-width: 767px) {
                        margin: 0 10px 0 0;
                    }
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                strong {
                    display: block;
                    font-size: 18px;
                    line-height: 1.4;
                    font-weight: 500;
                    margin-bottom: 12px;
                    margin-top: 20px;
                    text-align: center;
                    color: var(--dark-gray);
                    @media (max-width: 1023px) {
                        font-size: 16px;
                    }
                    @media (max-width: 767px) {
                        margin: 0;
                    }
                }
                &:nth-child(2) {
                    .service_icon {
                        padding: 8px;
                        @media (max-width: 1023px) {
                            padding: 10px;
                        }
                    }
                }
                &:hover {
                    .service_box_inner {
                        box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
                    }
                }
            }
        }
    }
}

.interactive_learning {
	background-color: rgba(202, 130, 255, 0.12);
	padding: 100px 0;
	position: relative;
    @media (max-width: 767px) {
        padding: 40px 0;
        text-align: center;
    }
	.interactive_learning_wrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
        position: relative;
		.interactive_learning_content {
			width: 420px;
            @media (max-width: 991px) {
                width: 280px;
            }
            @media (max-width: 767px) {
                width: 100%;
            }
			h2 {
				font-weight: 600;
				font-size: 34px;
				line-height: 38px;
				color: #090E11;
				margin-bottom: 25px;
                @media (max-width: 991px) {
                    font-size: 24px;
                    line-height: 1.2;
                    margin-bottom: 20px;
                }
                @media (max-width: 767px) {
                    margin-bottom: 0;
                    padding-bottom: 280px;
                }
			}
			.slick-dots {
				position: relative;
				bottom: 0;
				li {
					width: 100%;
					text-align: left;
					font-weight: 400;
					font-size: 18px;
					line-height: 34px;
					color: #090E11;
                    @media (max-width: 991px) {
                        font-size: 16px;
                    }
                    @media (max-width: 767px) {
                        text-align: center;
                    }
				}
				li.slick-active {
					color: #CA82FF;
				}
			}
			.slick-list {
				display: none !important;
			}
			.slick-arrow {
				display: none !important;
			}
			.btn {
				width: 171px;
				height: 45px;
				border: 1px solid #CA82FF;
				border-radius: 50px;
				font-weight: 400;
				font-size: 18px;
				line-height: 30px;
				text-align: center;
				color: #CA82FF;
				display: block;
				margin-top: 40px;
                @media (max-width: 991px) {
                    font-size: 16px;
                    width: 160px;
                    height: 40px;
                    line-height: 24px;
                }
                @media (max-width: 767px) {
                    margin: 20px auto 0;
                }
			}
		}
		.interactive_learning_slider {
			width: calc(100% - 420px);
			max-height: 406px;
			position: relative;
            @media (max-width: 991px) {
                width: calc(100% - 280px);
            }
            @media (max-width: 767px) {
                width: calc(100% - 20px);
                position: absolute;
                top: 75px;
                right: 0;
            }
			img {
				max-height: 406px;
				width: 100%;
				border-radius: 10px;
				display: block;
                @media (max-width: 767px) {
                    height: 250px;
                    object-fit: cover;
                }
			}
			.slick-arrow {
				width: 50px;
				height: 40px;
				background-color: #fff;
                &:before {
                    content: "";
                    width: 16px;
                    height: 16px;
                    display: block;
                    background-size: 16px;
                    margin: auto;
                    background-image: url('../../assets/images/down-arrow.png');
                }
			}
			.slick-arrow.slick-prev {
				left: -25px;
				top: calc(50% - 20px);
				z-index: 1;
				border-radius: 10px 10px 0px 0px;
                padding-top: 10px;
                &:before {
                    transform: rotate(180deg);
                }
			}
			.slick-arrow.slick-next {
				left: -25px;
				top: calc(50% - -20px);
				border-radius: 0px 0px 10px 10px;
                padding-bottom: 10px;
			}
		}
	}
	&:before {
		content: "";
		position: absolute;
		right: 0;
		top: 0;
		width: 30%;
		height: 100%;
		background: rgba(202, 130, 255, 0.25);
	}
}


.testimonial_slider {
    background-image: url('../../assets/images/testimonial-bg.png');
    padding: 100px 0;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #82B6FF;
        opacity: 0.9;
    }
    h2 {
        text-align: center;
        color: #fff;
        font-size: 36px;
        margin: 0 0 30px 0;
        @media (max-width: 767px) {
            font-size: 26px;
            margin: 0 0 15px 0;
        }
    }
    .testimonial_wrapper {
        .slick-list {
            max-width: 75%;
            margin: 0 auto 30px;
            @media (max-width: 767px) {
                margin: 0 auto 15px;
            }
            .slick-slide > div {
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 136px;
            }
        }
        .slide_item {
            p {
                color: #fff;
                font-weight: 600;
                font-size: 22px;
                line-height: 32px;
                text-align: center;
                @media (max-width: 767px) {
                    font-size: 16px;
                }
            }
            span {
                font-weight: 400;
                font-size: 20px;
                line-height: 23px;
                text-align: center;

                color: rgba(255, 255, 255, 0.6);
                display: block;
                @media (max-width: 767px) {
                    font-size: 16px;
                }
            }
        }
    }
    .slick-dots {
        position: unset;
        li {
            width: auto;
            height: auto;
            margin: 0;
            &.slick-active {
                button {
                    background: #ff9668;
                }
            }
            button {
                width: 10px;
                height: 10px;
                padding: 0;
                background: #f3f3f3;
                border-radius: 100px;
                margin: 0 5px;
                &:before {
                    content: "";
                    width: auto;
                    height: auto;
                }
            }
        }
    }
    .bottom-btn {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        @media (max-width: 767px) {
            display: block;
        }
        a.btn.btn-primary {
            @media (max-width: 767px) {
                font-size: 16px;
                width: 100%;
            }
        }
        .primary-btn {
            margin-right: 15px;
            @media (max-width: 767px) {
                margin-right: 0;
                margin-bottom: 15px;
            }
        }
        .secondary-btn {
            a.btn.btn-primary {
                background: #ff9668;
                border-color: #ff9668;
                color: #fff;
                &:hover {
                    background: #fff;
                    border-color: #fff;
                    color: #ff9668;
                }
            }
        }
    }
    button {
        &.slick-prev {
            left: 0;
            width: auto;
            height: auto;
            &:before {
                content: "";
                background-image: url(../../assets/images/prev-white.png);
                opacity: 1;
                height: 44px;
                background-size: contain;
                display: block;
                background-repeat: no-repeat;
                width: 44px;
                background-position: center;
                line-height: normal;
                @media (max-width: 767px) {
                    height: 24px;
                    width: 24px;
                }
            }
        }
        &.slick-next {
            right: 0;
            width: auto;
            height: auto;
            &:before {
                content: "";
                background-image: url(../../assets/images/next-white.png);
                opacity: 1;
                height: 44px;
                background-size: contain;
                display: block;
                background-repeat: no-repeat;
                width: 44px;
                background-position: center;
                line-height: normal;
                @media (max-width: 767px) {
                    height: 24px;
                    width: 24px;
                }
            }
        }
    }
}


button {
    background-color: #8EAFE2;
    border-radius: 30px;
    color: var(--white);
    position: relative;
    display: inline-block;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    padding: 18px 40px;
    transition: all 300ms ease-in-out;
    border: none;
}

.btn {
    &.btn-primary {
        font-size: 1.2rem;
        font-weight: 600;
        border-radius: 30px;
        padding: 10px 40px;
        position: relative;
        &:after {
            content: "";
            position: absolute;
            left: 7.5px;
            top: 6.5px;
            width: calc(100% - 15px);
            height: calc(100% - 15px);
            border: 2px dashed var(--very-light-orange);
            border-radius: 20px;
            display: none;
        }
    }
}


.home_page .home_conatiner {
    min-height: calc(100vh - 259px);
}


/** Text Animation **/

@-webkit-keyframes fadeInUpSD {
    0% {
      opacity: 0;
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  @keyframes fadeInUpSD {
    0% {
      opacity: 0;
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .fadeInUpSD {
    -webkit-animation-name: fadeInUpSD;
    animation-name: fadeInUpSD;
  }
  
  .slick-active .slide_content {
    animation-name: fadeInUpSD;
    animation-duration: 1s;
  }

  iframe {
    border: 1px solid;
    box-shadow: 5px 5px 10px #888888;
  }