.inner_banner_section {
    background: linear-gradient(
        180deg,
        rgb(255 150 104 / 10%) 0%,
        rgb(130 182 255 / 10%) 100%
    );
    mix-blend-mode: normal;
    padding: 7.5rem 0 3.375rem;
    position: relative;
    text-align: center;
    &:before {
        content: '';
        background-image: url('../../assets/images/banner-overlay.png');
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    h1 {
        font-weight: 700;
        font-size: 48px;
        line-height: 1.2;
        color: var(--very-light-orange);
        margin: 0;
        position: relative;
        @media (max-width: 991px) {
            font-size: 38px;
        }
        @media (max-width: 767px) {
            font-size: 24px;
            line-height: 1;
            margin-bottom: 5px;
        }
        span {
            color: var(--very-light-orange);
            display: block;
        }
    }
    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #090E11;
        margin: 5px 0 0;
        @media (max-width: 991px) {
            font-size: 14px;
            line-height: 1.2;
        }
    }
}

.intela_list_section {
    padding: 70px 0;
    @media (max-width: 767px) {
        padding: 40px 0;
    }
    .intela_list_wrapper {
        .intela_item {
            background-color: rgba(130, 182, 255, 0.12);
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            border-radius: 200px 0px 0px 200px;
            overflow: hidden;
            padding: 50px 110px;
            margin-bottom: 40px;
            @media (max-width: 991px) {
                padding: 50px 20px 50px 50px;
            }
            @media (max-width: 767px) {
                border-radius: 0;
                padding: 20px !important;
            }
            .intela_item_image {
                width: 50%;
                @media (max-width: 767px) {
                    width: 100%;
                }
                img {
                    max-width: 100%;
                    max-height: 264px;
                }
            }
            .intela_item_content {
                width: 50%;
                padding-left: 40px;
                @media (max-width: 991px) {
                    padding-left: 20px;
                }
                @media (max-width: 767px) {
                    padding: 20px 0 0 !important;
                    width: 100%;
                }
                h2 {
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 38px;
                    color: #090E11;
                    position: relative;
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                    @media (max-width: 991px) {
                        font-size: 24px;
                        line-height: 1.2;
                    }
                    @media (max-width: 767px) {
                        font-size: 20px;
                        padding-bottom: 10px;
                        margin-bottom: 10px;
                    }
                    &:before {
                        content: "";
                        width: 50px;
                        height: 4px;
                        background-color: #FF9668;
                        position: absolute;
                        bottom: 0;
                        @media (max-width: 767px) {
                            height: 2px;
                        }
                    }
                }
                p {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 1.4;
                    color: #090E11;
                    margin: 0 0 0;
                    @media (max-width: 991px) {
                        font-size: 14px;
                    }
                }
            }
            &:nth-child(odd) {
                background-color: rgba(202, 130, 255, 0.12);
                border-radius: 0px 200px 200px 0px;
                @media (max-width: 991px) {
                    padding: 50px 50px 50px 20px;
                }
                @media (max-width: 767px) {
                    border-radius: 0;
                }
                .intela_item_content {
                    order: -1;
                    padding-left: 0;
                    padding-right: 70px;
                    @media (max-width: 991px) {
                        padding-right: 20px;
                    }
                    @media (max-width: 767px) {
                        order: 1;
                    }
                }
                .intela_item_image {
                    text-align: right;
                }
            }
            &:last-child {
                margin: 0;
            }
        }
    }
}

.intela_cta_section {
    background-color: #CA82FF;
    padding: 40px 0;
    @media (max-width: 767px) {
        padding: 20px 0;
    }
    .intela_cta_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 767px) {
            display: block;
            text-align: center;
        }
        .intela_cta_content {
            @media (max-width: 767px) {
                margin-bottom: 20px;
            }
            h2 {
                font-weight: 600;
                font-size: 32px;
                line-height: 38px;
                color: #FFFFFF;
                margin: 0 0 5px;
                @media (max-width: 991px) {
                    font-size: 24px;
                    line-height: 1;
                }
            }
            p {
                font-weight: 400;
                font-size: 18px;
                line-height: 21px;
                color: #FFFFFF;
                margin: 0;
                @media (max-width: 991px) {
                    font-size: 14px;
                }
            }
        }
    }
}
