.fig_simple_display_group {
&:nth-child(n+1) {
	margin-bottom: 30px;
}
.fig_simple_display {
	.fig_question_display {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;
		.fig_question_list {
			margin: 0;
			padding-left: 15px;
			width: calc(100% - 43px);
			@media (max-width: 767px) {
				padding: 8px 0 0 0;
				width: 100%;
			}
			li {
				list-style-type: none;
				font-weight: 400;
				font-size: 18px;
				line-height: 21px;
				color: var(--mostly-black);
				width: 100%;
				display: inline-block;
				counter-increment: list-number;
				position: relative;
				padding-left: 25px;
				@media (max-width: 767px) {
					font-size: 16px;
					line-height: 19px;
				}
				&:marker {
					font-weight: 500;
				}
				&:not(:last-child) {
					margin-bottom: 20px;
				}
				// .string {
				// 	display: inline-block;
				// 	// max-width: calc(100% - 76px);
				// 	max-width: 100% !important;
				// }
				.icons {
					display: inline-block;
					vertical-align: top;
					margin-top: 8px;
				}
				.info_icon {
					cursor: pointer;
					display: inline-block;
					&.blub {
						svg {
							width: 20px;
							path {
								fill: var(--very-light-blue);
							}
						}
					}
				}
				.answere {
					font-weight: 500;
					font-size: 16px;
					line-height: 19px;
					padding: 8px 15px;
					background: var(--very-soft-blue);
					border-radius: 1px 4px 4px 1px;
					display: inline-block;
					margin-top: 5px;
					margin-left: 12px;
					position: relative;
					@media (max-width: 767px) {
						font-size: 14px;
						line-height: 17px;
					}
					&:before {
						content: '';
						border-top: 17px solid transparent;
						border-bottom: 17px solid transparent;
						border-right: 11px solid var(--very-soft-blue);
						position: absolute;
						left: -10px;
						top: 0px;
						bottom: 0;
					}
				}
				span {
					display: inline;
					font-size: initial;
					font-weight: 400;
					line-height: inherit;
					color: inherit;
					position: relative;
					padding-right: 36px;
				}
				input {
					border: 1px solid var(--light-gray);
					padding: 10px 20px;
					background: var(--white);
					border-radius: 10px;
					max-width: 180px;
					height: 40px;
					font-size: 18px;
					@media (max-width: 767px) {
						font-size: 16px;
					}
					&:focus {
						outline: none;
						border: 2px solid var(--light-blue);
					}
				}
				.question_ans_wrong {
					border: 2px solid var(--soft-red);
				}
				.question_ans_right {
					border: 2px solid var(--strong-cyan);
				}
			}
		}
		.fig_question_list:has(.fig_list_worps) {
			li {
				&:before {
					content: counter(list-number, upper-alpha) '. ';
					position: absolute;
					left: 0;
					top: 10px;
				}
			}
		}
	}
	.fig_list_worps {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin-bottom: 30px;
		span {
			background: var(--very-light-yellow);
			border-radius: 5px;
			font-weight: 400;
			font-size: 16px;
			line-height: 19px;
			color: var(--mostly-black);
			padding: 2px 10px;
			margin-bottom: 10px;
			@media (max-width: 767px) {
				font-size: 14px;
				line-height: 17px;
			}
			&:not(:last-child) {
				margin-right: 10px;
			}
		}
	}
}
}