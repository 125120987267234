.pricing_page {
	.loader_section {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.pricing_content {
		padding: 60px 0;
		h2 {
			font-size: 16px;
			font-weight: 400;
			line-height: 1.4;
			margin-bottom: 20px;
		}
	}
	.pricing_table {
		.btn.btn-primary {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 16px;
			font-weight: 500;
			background: var(--very-light-orange);
			border-radius: 40px;
			color: var(--white);
			border: none;
			padding: 10px 10px;
			div {
				margin-left: 10px;
				padding-bottom: 3px;
				path {
					fill: var(--white);
				}
			}
		}
		.pricing_page_expires {
			margin-top: 0px;
			border-top: 1px solid var(--very-light-gray);
			padding-top: 10px;
			p {
				font-size: 16px;
				line-height: 1.6;
				max-width: 510px;
				margin-left: auto;
				margin-bottom: 0;
				color: var(--dark-gray);
				.btn {
					padding: 0;
					display: unset;
					width: auto;
					font-size: inherit;
					color: var(--bs-link-color);
					text-decoration: underline;
					font-weight: inherit;
					background: transparent;
					margin-left: 4px;
					line-height: inherit;
					margin-top: -1px;
				}
			}
			&.text-right {
				text-align: right;
			}
		}
		.inner_table {
			max-width: 100%;
			overflow: auto;
			table {
				width: 100%;
				@media (max-width: 767px) {
					min-width: 600px;
				}
				tr {
					th {
						@media (max-width: 767px) {
							max-width: 20%;
						}
					}
				}
				thead {
					th {
						padding: 15px;
						color: var(--white);
						background: var(--very-light-blue);
						font-weight: 500;
						font-size: 20px;
						line-height: 1.2;
						text-align: center;
						@media (max-width: 991px) {
							font-size: 16px;
						}
						@media (max-width: 767px) {
							font-size: 14px;
							padding: 10px;
						}
						&:first-child {
							text-align: left;
							border-radius: 20px 0 0 0;
							@media (max-width: 767px) {
								border-radius: 10px 0 0 0;
							}
						}
						&:last-child {
							border-radius: 0 20px 0 0;
							@media (max-width: 767px) {
								border-radius: 0 10px 0 0;
							}
						}
						.label {
							span {
								display: block;
								font-size: 14px;
								font-weight: 400;
								color: var(--gray);
								line-height: 1.2;
								min-height: 34px;
								@media (max-width: 991px) {
									font-size: 12px;
									line-height: 1;
								}
							}
							.info {
								margin-left: 5px;
								margin-top: -2px;
								cursor: pointer;
								position: relative;
								&:before {
									border: 10px solid transparent;
									content: ' ';
									display: block;
									position: absolute;
									z-index: 2;
									border-bottom-color: var(--white);
									left: -3px;
									top: 10px;
									visibility: hidden;
									opacity: 0;
									transition: 0.3s all;
								}
								&:hover {
									&:before {
										visibility: visible;
										opacity: 1;
									}
									.custom-tooltip {
										opacity: 1;
										visibility: visible;
									}
								}
								svg {
									width: 14px;
									@media (max-width: 767px) {
										width: 12px;
									}
									path {
										fill: var(--white);
									}
								}
								.custom-tooltip {
									position: absolute;
									right: -13px;
									font-size: 12px;
									background: var(--white);
									padding: 10px 10px;
									border-radius: 10px;
									color: var(--dark-gray);
									font-weight: 400;
									width: 150px;
									margin: auto;
									box-shadow: 0px 4px 12px rgb(0 0 0 / 10%);
									top: 30px;
									opacity: 0;
									visibility: hidden;
									transition: 0.3s all;
									cursor: default;
									ul {
										padding: 0 0 0 15px;
										margin: 0;
										text-align: left;
										li {
											margin: 0 0 5px;
											&:last-child {
												margin-bottom: 0;
											}
										}
									}
								}
							}
						}
						h6 {
							font-size: 14px;
							margin-top: 3px;
							margin-bottom: 0;
							font-weight: 300;
							@media (max-width: 767px) {
								font-size: 12px;
							}
							@media (max-width: 575px) {
								font-size: 10px;
							}
						}
					}
				}
				tbody {
					tr {
						&:nth-child(even) {
							th {
								background: rgb(221 221 221 / 20%);
							}
						}
						&:nth-child(even) {
							td {
								background: rgb(221 221 221 / 20%);
							}
						}
						&:last-child {
							th {
								border-bottom: none;
							}
							td {
								border-bottom: none;
							}
						}
						th {
							border-bottom: 1px solid var(--very-light-gray);
							padding: 15px;
							color: var(--dark-gray);
							font-weight: 400;
							font-size: 16px;
							line-height: 1.2;
							width: 40%;
							@media (max-width: 767px) {
								font-size: 14px;
								padding: 10px;
							}
							@media (max-width: 575px) {
								font-size: 12px;
								padding: 10px 5px;
							}
						}
						td {
							border-bottom: 1px solid var(--very-light-gray);
							padding: 15px;
							text-align: center;
							width: 20%;
							@media (max-width: 767px) {
								padding: 10px;
							}
							@media (max-width: 575px) {
								padding: 10px 5px;
							}
							&.checkmarkicon {
								img {
									background: rgb(43 196 138 / 25%);
								}
							}
							&.crossicon {
								img {
									background: rgb(244 67 54 / 25%);
								}
							}
							img {
								padding: 10px;
								border-radius: 100px;
								max-width: 40px;
								@media (max-width: 767px) {
									padding: 7px;
									border-radius: 100px;
									max-width: 28px;
								}
							}
						}
					}
				}
			}
		}		
		.pricing_page_btn {
			margin-top: 30px;
			display: flex;
			justify-content: flex-end;
			@media (max-width: 767px) {
				justify-content: center;
				margin-top: 15px;
			}
			.renew_note {
				flex: 1;
				text-align: center;
				background-color: rgba(43, 196, 138, 0.25);
				padding: 10px 0;
				font-size: 16px;
			}
			.inner {
				span {
					display: block;
					text-align: center;
					font-size: 16px;
					margin-bottom: 5px;
					@media (max-width: 767px) {
						font-size: 14px;
					}
				}
				button {
					background: var(--very-light-orange);
					box-shadow: 0px 7px 12px rgb(255 150 104 / 50%);
					border-radius: 40px;
					color: var(--white);
					border: none;
					padding: 16.5px 30px 16.5px 50px;
					width: 100%;
					font-size: 20px;
					line-height: 26px;
					margin: 20px 0 2px 0;
					font-weight: 500;
					transition: all 0.5s ease;
					@media (max-width: 767px) {
						font-size: 16px;
						padding: 10px 20px;
						margin-top: 5px;
					}
					&:hover {
						transform: translateY(-3px);
					}
				}
			}
		}
		.pricing-info {
			margin-top: 40px;
			border-top: 6px solid rgb(130 182 255 / 40%);
			padding-top: 40px;
			h3 {
				font-size: 20px;
				font-weight: 500;
				margin: 0 0 15px 0;
				@media (max-width: 767px) {
					font-size: 18px;
				}
			}
			p, .text {
				font-size: 16px;
				line-height: 22px;
				margin-bottom: 1rem;
				@media (max-width: 767px) {
					font-size: 14px;
					line-height: 20px;
				}
				b {
					font-weight: 500;
				}
			}
			.discount_table {
				max-width: 100%;
				margin: 30px 0;
				box-shadow: 0px 4px 12px rgb(0 0 0 / 10%);
				white-space: nowrap;
				overflow: auto;
				scrollbar-color: var(--very-light-blue) transparent;
				scrollbar-width: 8px;
				border-radius: 20px 20px 0 0;
				@media (max-width: 767px) {
					border-radius: 10px 10px 0 0;
				}
				&::-webkit-scrollbar {
					width: 8px;
					height: 8px;
				}
				&::-webkit-scrollbar-corner {
					background-color: transparent;
					border-radius: 12px;
				}
				&::-webkit-scrollbar-track {
					background-color: transparent;
					border-radius: 12px;
					background-clip: content-box;
					border: 2px solid transparent;
				}
				&::-webkit-scrollbar-thumb {
					background-color: var(--very-light-blue);
					border-radius: 12px;
				}
				table {
					width: 100%;
					thead {
						tr {
							th {
								padding: 10px 15px;
								font-size: 16px;
								font-weight: 500;
								line-height: 1.2;
								background: var(--dark-gray);
								color: var(--white);
								@media (max-width: 767px) {
									padding: 10px;
									font-size: 14px;
								}
							}
						}
					}
					tbody {
						tr {
							&:nth-child(even) {
								td {
									background: rgba(221, 221, 221, 0.2);
								}
							}
							td {
								padding: 10px 15px;
								font-size: 14px;
								font-weight: 400;
							}
						}
					}
				}
			}
		}
	}
}
