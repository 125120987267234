.mcq_simple_display {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	margin-bottom: 30px;
	.mcq_simple_display_header {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 13px;
		width: 43px;
		@media (max-width: 767px) {
			width: 100%;
			margin-bottom: 0;
		}
		h4 {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			font-weight: 400;
			font-size: 18px;
			line-height: 21px;
			color: var(--mostly-black);
			margin-left: 16px;
			margin-bottom: 0;
			@media (max-width: 767px) {
				font-size: 16px;
				line-height: 19px;
			}
			.info_icon {
				margin-left: 20px;
				cursor: pointer;
			}
		}
	}
	.mcq_simple_display_content {
		width: calc(100% - 43px);
		padding-left: 16px;
		@media (max-width: 767px) {
			width: 100%;
			padding: 8px 0 0 0;
		}
		.item {
			&:not(:last-child) {
				margin-bottom: 15px;
			}
		}
		h4 {
			display: flex;
			justify-content: flex-start;
			font-weight: 400;
			font-size: 18px;
			line-height: 21px;
			color: var(--mostly-black);
			margin-bottom: 15px;
			width: calc(100% - 36px);
			@media (max-width: 767px) {
				font-size: 16px;
				line-height: 19px;
			}
		}
		.icons {
			display: flex;
		}
		.form-check {
			position: relative;
			padding: 0;
			margin-bottom: 15px;
			display: inline-block;
			margin-right: 2rem !important;
			&:last-child {
				margin-bottom: 0;
			}
			input {
				position: absolute;
				right: 0;
				top: 0;
				width: 100%;
				opacity: 0;
				cursor: pointer;
				height: 100%;
				z-index: 9;
				border: 1px solid var(--light-gray);
				border-radius: 10px;
				&:before {
					content: '';
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					background: transparent;
				}
				&:checked {
					+ .form-check-label {
						&:before {
							background: var(--light-blue);
						}
						&.question_ans_right {
							&:before {
								content: attr(data-index);
								border: 2px solid var(--strong-cyan);
							}
						}
						&.question_ans_wrong {
							&:before {
								content: attr(data-index);
								border: 2px solid var(--soft-red);
							}
						}
					}
					&.wrong {
						+ .form-check-label {
							&:before {
								// background: var(--soft-red);
								border-color: var(--soft-red);
							}
						}
					}
					&.right {
						+ .form-check-label {
							&:before {
								// background: var(--green	);
								border-color: var(--green);
							}
						}
					}
				}
			}
			.form-check-input {
				&:checked + .form-check-label {
					&:after {
						content: '';
						position: absolute;
						left: 9px;
						top: 0;
						opacity: 0.6;
					}
				}
			}
			.form-check-label {
				position: relative;
				padding-left: 45px;
				font-weight: 400;
				font-size: 18px;
				line-height: 30px;
				color: var(--black);
				cursor: pointer;
				@media (max-width: 767px) {
					font-size: 14px;
					line-height: 17px;
				}
				p {
					margin-bottom: 0;
				}
				&:before {
					content: attr(data-index);
					background: var(--white);
					border: 1px solid var(--light-gray);
					border-radius: 10px;
					width: 30px;
					height: 30px;
					position: absolute;
					left: 0;
					top: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					transform: translateY(-50%);
				}
				&.question_ans_right {
					&:before {
						content: attr(data-index);
						border: 2px solid var(--strong-cyan);
					}
				}
				&.question_ans_wrong {
					&:before {
						content: attr(data-index);
						border: 2px solid var(--soft-red);
					}
				}
				span {
					@media (max-width: 767px) {
						margin-top: 9px;
						display: block;
					}
				}
			}
		}
		ol {
			padding-left: 0;
			flex-wrap: wrap;
			list-style-type: none;
		}
	}
}
