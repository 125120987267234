.timed_section {
  height: 100vh;
  background: #f5f7fb;

  .questions_wrapper {
    display: flex;
    flex-wrap: wrap;
    height: 100vh;

    .question_section_wrapper {
      width: 100%;

      .timer_section {
        margin-top: 10px;
        .timer_wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: var(--white);
          border-radius: 20px;
          button {
            &.exit_btn {
              background: transparent;
              border: none;
              padding: 0 12px;

              svg {
                width: 40px;
                height: 40px;

                path {
                  stroke: var(--very-light-blue);
                }

                @media (max-width: 767px) {
                  width: 31px;
                  height: 31px;
                }
              }
            }

            &:disabled {
              .arrow_icon {
                svg {
                  fill: var(--white);
                }
              }
            }

            &.grad_submit_btn {
              background: var(--very-light-orange);
              color: var(--white);
              border: none;
              padding: 11px 28px;
              font-weight: 500;
              font-size: 18px;
              transition: all 0.5s ease;
              &:hover {
                transform: translateY(-3px);
              }

              @media (max-width: 767px) {
                padding: 5px 10px;
                font-weight: 500;
                font-size: 14px;
              }
            }
          }
        }
      }

      .question_section {
        height: calc(100vh - 140px);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        padding: 20px;
        overflow-y: auto;
        @media (max-width: 767px) {
          align-items: flex-start;
        }
        > .container {
          height: 100%;
        }
        .questions_wrap {
          background: var(--white);
          border-radius: 20px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 12px 0;
          position: relative;
          @media (max-width: 767px) {
            width: 100%;
          }
          .container {
            display: flex;
            // justify-content: center;
            max-height: 100%;
            overflow: auto;
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              border-radius: 10px;
              background-color: #f5f5f5;
            }
            &::-webkit-scrollbar {
              background-color: #f5f5f5;
              height: 5px;
              width: 5px;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              background-color: #555;
              width: 5px;
            }
          }
          .ucq_simple_display {
            .ucq_base_display_options_radio {
              ol {
                li {
                  .form-check-input[type="radio"] {
                    background: var(--white);
                  }
                }
              }
            }
          }

          .mcq_simple_display {
            .mcq_simple_display_content {
              ol {
                @media (max-width: 767px) {
                  flex-direction: column;
                }
                list-style-type: none;
              }

              .form-check {
                .form-check-label {
                  span {
                    @media (max-width: 767px) {
                      margin-top: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .questions_list_box {
      position: absolute;
      bottom: 10px;
      width: calc(100% - 30px);
      z-index: 2;

      .question_list_section {
        background: var(--white);
        border-radius: 20px;
        display: flex;
        align-items: center;
        padding: 0 10px;

        .arrow_btn {
          background: transparent;
          border: none;
          padding: 0;
          width: 40px;
          height: 40px;
          background: var(--very-light-blue);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--white);
          font-size: 14px;

          svg {
            width: 15px;
            height: 15px;
            fill: var(--white);
          }

          &.prev {
            padding: 0 10px;
            border-radius: 20px 0 0 20px;
            width: auto;
            height: 45px;
            margin-right: 10px;
            margin-left: 10px;

            &:focus-visible {
              box-shadow: none;
            }

            @media (max-width: 767px) {
              width: auto;
              height: 35px;
              margin-left: 10px;
            }

            .arrow_icon {
              margin-right: 5px;
            }
          }

          &.next {
            padding: 0 10px;
            border-radius: 0 20px 20px 0;
            width: auto;
            height: 45px;

            &:focus-visible {
              box-shadow: none;
            }

            @media (max-width: 767px) {
              width: auto;
              height: 35px;
            }

            .arrow_icon {
              margin-left: 5px;
            }
          }
        }

        .question_list_section_wrapper {
          display: flex;
          overflow: hidden;
          width: 100%;
          overflow-x: auto;
          padding: 10px 0;

          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            background-color: #f5f5f5;
          }

          &::-webkit-scrollbar {
            background-color: #f5f5f5;
            height: 5px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #555;
            width: 5px;
          }

          .dots {
            height: 35px;
            width: 35px;
            min-width: 35px;
            cursor: pointer;
            margin: 5px;
            border-radius: 78px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            color: #000;
            background: linear-gradient(225deg, #f9f9f9, #d2d2d2);
            border-radius: 50%;
            border: 1px solid #f9f9f9;
            opacity: 0.5;
            transform: scale(0.8);
            transition: all 0.3s;

            &:hover {
              border-color: var(--very-light-blue);
              color: var(--very-light-blue);
              opacity: 1;
            }

            &:after {
              content: "";
              width: 12px;
              height: 12px;
              background-color: red;
              border-radius: 100%;
              position: absolute;
              right: -2px;
              top: -2px;
              opacity: 0;
            }

            &.current_que {
              border-color: var(--very-light-blue);
              color: var(--very-light-blue);
              opacity: 1;
              transform: scale(1);

              &.question_attempted {
                color: #000;
              }

              &.wrong {
                color: var(--white);
                border-color: rgba(253, 93, 93, 1);
              }
            }

            &.mark_for_review {
              &:after {
                opacity: 1;
              }
            }

            &.active {
              background: rgba(130, 182, 255, 1);
              box-shadow: none;
              opacity: 1;
            }

            &.unattempted {
              background: var(--white);
              box-shadow: none;
              opacity: 1;
            }

            &.right {
              background: var(--green);
              box-shadow: none;
              color: #000;
              opacity: 1;
            }

            &.wrong {
              background: rgba(253, 93, 93, 1);
              box-shadow: none;
              opacity: 1;
            }

            &.question_attempted {
              color: #fff;
              background: rgba(130, 182, 255, 1);
              box-shadow: none;
              opacity: 1;
              &:hover {
                color: #fff;
              }
            }

            @media (max-width: 767px) {
              height: 30px;
              width: 30px;
              min-width: 30px;
              margin: 3px;
            }
          }

          @media (max-width: 767px) {
            padding: 5px 0;
          }
        }
      }
    }
  }
}

.timer_left_buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.timer_left_buttons .warning_btn {
  padding: 0;
  background-color: transparent;
  border: none;
  margin-left: 20px;
}

.timer_left_buttons .warning_btn:active,
.timer_left_buttons .warning_btn:hover {
  background-color: transparent !important;
}

.timer_left_buttons .warning_btn .warning_icon svg {
  width: 30px;
  height: 30px;
  fill: #eead1e;
}

.timed_section_loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 12;
  .spinner-border {
    width: 40px;
    height: 40px;
  }
}

.mark_for_review_checkbox {
  position: absolute;
  bottom: 10px;
  right: 20px;
  margin: 0;
  .form-check-input {
    border: 1px solid var(--light-gray);
  }
}

.cs-tooltip .tooltip-inner {
  max-width: max-content;
}

.cursor-progress-flag-timmed {
  .flag-img-timmed {
    position: absolute;
    top: auto;
    left: auto;
    margin-top: 46px;
    margin-left: 10px;
    cursor: pointer;
    width: 25px;
    height: 25px;
  }
}

button.arrow_btn.next.next_btn.btn.btn-primary {
  margin: 0px !important;
}

.timmer_section_div {
  width: 80px !important;
  height: 33px !important;
}
