.fib_simple_display {
	display: flex;
	flex-wrap: wrap;
	&:not(:last-child) {
		margin-bottom: 30px;
	}
	span {
		display: block;
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		color: var(--mostly-black);
		width: 43px;
		@media (max-width: 767px) {
			font-size: 16px;
			line-height: 19px;
			width: 100%;
		}
	}
	.fib_simple_display_text {
		padding-left: 16px;
		width: calc(100% - 43px);
		@media (max-width: 767px) {
			width: 100%;
			padding: 8px 0 0 0;
		}
		.fib_simple_display_input {
			background: var(--white);
			border: none;
			border: 1px solid var(--light-gray);
			padding: 10px 20px;
			border-radius: 10px;
			margin: 0 5px;
			height: 40px;
			font-size: 18px;
			width: 100%;
			max-width: 180px;
			@media (max-width: 767px) {
				font-size: 16px;
			}
			&:focus {
				outline: none;
			}
			&.question_ans_wrong {
				border: 2px solid var(--soft-red);
			}
			&.question_ans_right {
				border: 2px solid var(--strong-cyan);
			}
		}
		h4 {
			display: inline-block;
			justify-content: flex-start;
			font-weight: 400;
			font-size: 18px;
			line-height: 21px;
			color: var(--mostly-black);
			margin-bottom: 15px;
			@media (max-width: 767px) {
				font-size: 16px;
				line-height: 19px;
			}
			.info_icon {
				margin-left: 20px;
				cursor: pointer;
			}
		}
		.fib_simple_display_radio {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-wrap: wrap;
			.form-check {
				position: relative;
				padding: 0;
				margin: 0 20px 20px 0;
				@media (max-width: 991px) {
					margin: 0 10px 10px 0;
				}
				.form-check-label {
					background: var(--white);
					border-radius: 10px;
					width: 137px;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #cccccc;
					position: unset;
					&:after {
						display: none;
					}
					@media (max-width: 767px) {
						width: auto;
						padding: 10px 15px;
					}
					p {
						margin-bottom: 0;
					}
				}
				.form-check-input {
					position: absolute;
					left: 0;
					top: 0;
					margin: 0;
					width: 100%;
					height: 100%;
					opacity: 0;
					cursor: pointer;
					display: block;
					&:checked {
						+ label {
							background-color: grey;
						}
					}
				}
				.question_ans_right {
					border: 2px solid var(--strong-cyan);
				}
				.question_ans_wrong {
					border: 2px solid var(--soft-red);
				}
			}
		}
	}
}
