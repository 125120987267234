.matrix_match_display {
  margin-bottom: 30px;

  .header_wrapper {
    display: flex;
    margin-bottom: 13px;

    .matrix_match_display_options_radio {
      width: calc(100% - 43px);
      padding-left: 16px;

      .input-wrapper {
        margin: 10px 0;
      }

      .matrix_options_table {
          margin-bottom: 20px;
          width: 100%;
          overflow: auto;
      }

      .form-check-inline {
        position: relative;

        .form-check-label {
          background-color: #fff;
          border: 1px solid #ccc;
          border-radius: 50%;
          cursor: pointer;
          height: 28px;
          left: 0;
          position: absolute;
          top: 0;
          width: 28px;
          text-align: center;

          &.question_ans_right {
            border: 2px solid var(--strong-cyan);
          }

          &.question_ans_wrong {
            border: 2px solid var(--soft-red);
          }

          &:after {
            border: 2px solid #fff;
            border-top: none;
            border-right: none;
            height: 6px;
            position: absolute;
            width: 12px;
          }
        }

        .form-check-input[type="checkbox"] {
          visibility: hidden;

          &:checked+.form-check-label {
            background-color: var(--light-blue);

            &:after {
              opacity: 1;
            }

            &.question_ans_right {
              border: 2px solid var(--strong-cyan);
            }

            &.question_ans_wrong {
              border: 2px solid var(--soft-red);
            }
          }
        }
      }

      .column_headings {
        display: inline-flex;
        position: relative;
        padding-left: 42px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 4px;

        .single_column_heading {
          background-color: #fff;
          border-radius: 50%;
          height: 28px;
          width: 28px;
          text-align: center;
          margin-right: 3px
        }
      }

      .option_with_rows {
        display: flex;
        flex-wrap: wrap;

        .option_rows {
          display: flex;
          flex-direction: column;
          gap: 6px;
          margin-top: 11px;
          margin-right: 14px;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            width: 1px;
            height: calc(100% + 43px);
            top: -33px;
            right: -7px;
            background: #ccc;
          }
        }
      }

      .options_wrapper {
        display: flex;
        gap: 20px;

        .individual_option {
          display: flex;
          gap: 5px;
        }
        h4 {
          font-weight: 700;
        }
      }

      .center-label {
        display: flex;
        align-items: center;
      }

      .row_indicator {
        background-color: #fff;
        border-radius: 50%;
        height: 28px;
        width: 28px;
        text-align: center;
        display: inline-block;
        position: relative;
      }

      .right {
        border: 2px solid var(--strong-cyan);
      }

      .wrong {
        border: 2px solid var(--soft-red);
      }

      @media (max-width: 767px) {
        margin-left: 0;
      }
    }

    .info_icon {
      margin-left: 20px;
      cursor: pointer;
    }

    .ucq_heading {
      display: flex;
      padding-left: 16px;
      width: calc(100% - 43px);

      h4 {
        padding: 0px;
      }
    }
  }

  h4 {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--mostly-black);
    padding-left: 0px;
    margin-bottom: 15px;
    width: calc(100% - 43px);

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 17px;
      width: 100%;
      margin-bottom: 0px;
    }

    em {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }

    p {
      display: inline-block;
    }
  }

  .form-check {
    margin-bottom: 15px;

    .question_ans_right {
      border: 2px solid var(--strong-cyan);
    }

    .question_ans_wrong {
      border: 2px solid var(--soft-red);
    }
  }
}