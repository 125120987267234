.navbar {
	background-color: transparent;
	transition: all 0.4s ease-in-out;
	z-index: 9;
	&.sticky {
		transition: all 0.4s ease-in-out;
		background: rgba(255, 255, 255, 1);
		box-shadow: 0px 20px 20px -20px rgb(80 80 80 / 10%);
		.navbar-collapse {
			padding: 8px 0;
		}
		.light-theme {
			.navbar-collapse {
				.navbar-nav {
					.auth_buttons {
						a {
							color: #FF9668;
							&:hover {
								color: #fff;
							}
							+ a {
								color: #fff;
							}
						}
					}
					.nav-link {
						color: var(--mostly-black);
					}
				}
			}
			.navbar-brand {
				svg {
					path[fill="black"] {
						fill: var(--black);
					}
				}
			}
			button {
				&.navbar-toggler {
					.navbar-toggler-icon {
						background-image: url('../../assets/images/hamburger-dark.png');
					}
				}
			}
		}		
	}
	.light-theme {
		.navbar-collapse {
			.navbar-nav {
				.nav-link {
					color: var(--white);
					@media (max-width: 991px) { 
						color: var(--mostly-black);
					}
				}
				.auth_buttons {
					a {
						color: #fff;
						border-color: #fff;
						@media (max-width: 991px) { 
							color: #FF9668;
							border-color: #FF9668;
						}
						&:hover {
							color: #fff;
							border-color: #FF9668;
						}
						+ a {
							color: #fff;
							border-color: #FF9668;
						}
					}
				}
			}
		}
		.navbar-brand {
			svg {
				path[fill="black"] {
					fill: var(--white);
					transition: 0.5s all;
				}
			}
		}

		button {
			&.navbar-toggler {
				.navbar-toggler-icon {
					background-image: url('../../assets/images/hamburger.png');
				}
			}
		}
	}
	button {
		&.navbar-toggler {
			border: none;
			padding: 0;
			&:focus {
				outline: none;
				box-shadow: none;
			}
			.navbar-toggler-icon {
				background-image: url('../../assets/images/hamburger-dark.png');
			}
		}
	}
	.navbar-collapse {
		@media (max-width: 991px) { 
			background: #fff;
			padding: 8px 20px;
			border-radius: 10px;
			transition: 0.5s all;
		}
		.navbar-nav {
			@media (min-width: 992px) {
				align-items: center;
			}
			.auth_buttons {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				padding-left: 20px;
				margin-left: 20px;
				@media (max-width: 991px) {
					padding-left: 0px;
					margin-left: 0px;
					margin-top: 10px;
					border-left: 0;
				}
				a {
					width: 108px;
					height: 35px;
					border: 1px solid #FF9668;
					border-radius: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-weight: 400;
					font-size: 16px;
					line-height: 35px;
					color: #FF9668;
					text-decoration: none;
					&:hover {
						background-color: #FF9668;
						color: #fff;
					}
					+ a {
						background-color: #FF9668;
						margin-left: 15px;
						color: #fff;
					}
				}
			}
			.nav-link {
				font-weight: 400;
				font-size: 16px;
				line-height: 19px;
				color: var(--mostly-black);
				text-decoration: none;
				padding-left: 0;
				padding-right: 0;
				&:not(:first-child) {
					margin-left: 46px;
					@media (max-width: 991px) { 
						margin-left: 0; 
					}
				}
				&:hover {
					color: var(--very-light-orange);
				}
				&.active {
					color: var(--very-light-orange);
				}
				&:after {
					position: absolute;
					right: 0;
					top: 17px;
				}
				&.dropdown-toggle {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 127px;
					position: relative;
					padding-right: 20px;
				}
			}
			.dropdown-menu {
				padding: 0 10px;
				margin-top: 0;
				left: -40px;
				@media (max-width: 991px) { 
					border: none;
					left: unset;
				}
				.nav-link {
					margin: 0;
				}
				button {
					&.nav-link {
						border: none;
						margin: 0;
						background: transparent;
					}
				}
			}
			.nav-dropdown {
				font-weight: 400;
				font-size: 1rem;
				margin-left: 46px;
				@media (max-width: 991px) { 
					margin-left: 0;
				}
				.dropdown-menu {
					padding: 0;
					overflow: hidden;
					.nav-link {
						color: var(--mostly-black);
						margin: 0;
						padding-left: 10px;
						padding-right: 10px;
						&:hover {
							background-color: var(--very-light-orange);
							color: var(--white);
							.nav-icon {
								filter: brightness(0) invert(1);
							}
						}
					}
					button {
						width: 100%;
						text-align: left;
					}
				}
			}
		}
	}

	.nav-link {
		.nav-icon {
			width: 16px;
			margin-right: 5px;
		}
	}
}