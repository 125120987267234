
.success-page {
	min-height: calc(100vh - 46px);
	padding: 120px 0 50px;
	.loader_section {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 9;
	}
	.success_image {
		text-align: center;
		svg {
			width: 100%;
		}
	}
	.description {
		margin-top: 53px;
		text-align: center;
		@media (max-width: 767px) {
			margin-top: 25px;
		}
		p {
			margin-bottom: 20px;
			font-size: 18px;
			line-height: 25px;
			font-weight: 400;
			@media (max-width: 767px) {
				font-size: 16px;
				line-height: 21px;
				margin-bottom: 15px;
			}
		}
	}
	.back-btn {
		text-align: center;
		button {
			&.btn {
				&.btn-primary {
					background: var(--very-light-orange);
					// box-shadow: 0px 7px 12px rgb(255 150 104 / 50%);
					border-radius: 40px;
					color: var(--white);
					border: none;
					padding: 16.5px 30px 16.5px 30px;
					width: auto;
					font-size: 20px;
					line-height: 26px;
					margin: 20px 0 2px 0;
					font-weight: 500;
					transition: all 0.5s ease;
					@media (max-width: 767px) {
						font-size: 16px;
						line-height: 22px;
					}
					&:hover {
						transform: translateY(-3px);
					}
				}
			}
		}
	}
}
