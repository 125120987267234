.mxq_display_componet {
	display: flex;
	flex-wrap: wrap;
	&:not(:last-child) {
		margin-bottom: 30px;
	}
	.mxq_span {
		display: block;
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		color: var(--mostly-black);
		width: 43px;
		@media (max-width: 767px) {
			font-size: 16px;
			line-height: 19px;
			width: 100%;
		}
	}
	.mxq_display_componet_text {
		padding-left: 16px;
		width: calc(100% - 43px);
		@media (max-width: 767px) {
			width: 100%;
			padding: 8px 0 0 0;
		}
		.icons {
			display: flex;
		}
		.form-control {
			width: 100%;
			background: var(--white);
			border: 1px solid var(--light-gray);
			border-radius: 10px;
			padding: 10px 20px;
			height: 40px;
			// max-width: 650px;
			max-width: 220px;
			margin-right: 13px;
			font-size: 18px;
			@media (max-width: 1199px) {
				max-width: 450px;
			}
			@media (max-width: 767px) {
				font-size: 16px;
			}
			&:focus {
				border: 2px solid var(--light-blue);
			}
			&.question_ans_wrong {
				border: 2px solid var(--soft-red);
			}
			&.question_ans_right {
				border: 2px solid var(--strong-cyan);
			}
		}
		.input-tooltip {
			display: flex;
			align-items: center;
		}
		h4 {
			display: flex;
			justify-content: flex-start;
			font-weight: 400;
			font-size: 18px;
			line-height: 21px;
			color: var(--mostly-black);
			margin-bottom: 15px;
			@media (max-width: 767px) {
				font-size: 16px;
				line-height: 19px;
			}
			.katex-html {
				display: flex;
				flex-wrap: wrap;
			}
			.sound_icon {
				svg {
					height: 4rem;
					width: 4rem;
				}
			}
			.info_icon {
				margin-left: 20px;
				cursor: pointer;
			}
		}
	}
}
