.miq_simple_display {
  .miq_simple_display_header {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 13px;
    h4 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: var(--mostly-black);
      margin-bottom: 0;
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 19px;
      }
      .info_icon {
        cursor: pointer;
      }
    }
    .miq_simple_display_content {
      width: calc(100% - 43px);
      padding-left: 16px;
      display: flex;
      @media (max-width: 767px) {
        width: 100%;
        padding: 8px 0 0 0;
      }
    }
  }
  .drag_drop_page {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 46px;
    @media (max-width: 767px) {
      padding-left: 0;
    }
    .miq_container {
      width: 100%;
      @media (max-width: 767px) {
        margin-bottom: 15px;
      }
    }
    .drag_drop_wrapper {
      background-color: var(--lightblue);
      border-radius: 1rem;
      @media (max-width: 767px) {
        display: block;
      }
      .col {
        padding: 0;
        @media (max-width: 767px) {
          margin-top: 15px;
        }
        .container {
          @media (max-width: 767px) {
            padding: 0;
          }
        }
      }
      .mcq_lhs {
        border-radius: 1rem 0 0 1rem;
        .formLHS {
          margin: 15px 0 0 0;
          background-color: var(--lightcyan);
          border-radius: 0.5rem;
          h6 {
            margin: 0;
            background-color: var(--very-light-yellow);
            padding: 9px 20px;
            border-radius: 0.5rem;
            text-align: left;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            border: 2px solid var(--very-light-yellow);
            @media (max-width: 991px) {
              font-size: 14px;
            }
            &:focus,
            &:hover {
              color: transparent;
              &:after {
                content: attr(data-text);
                overflow: visible;
                text-overflow: inherit;
                background: #fff;
                position: absolute;
                left: auto;
                top: auto;
                width: auto;
                max-width: 20rem;
                border: 1px solid #eaebec;
                padding: 0 0.5rem;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
                white-space: normal;
                word-wrap: break-word;
                display: block;
                color: black;
                margin-top: -1.25rem;
              }
            }
          }
        }
        .form_svg_lhs {
          margin-top: 15px;
          display: flex;
          justify-content: center;
          .Lhs_svg {
            background-color: lightgrey;
            max-height: 4rem;
            max-width: 4rem;
            margin-top: 0;
            cursor: pointer;
            img {
              max-height: 4rem;
              max-width: 4rem;
              margin-top: 0;
            }
          }
        }
        h3 {
          text-align: center;
          font-size: 24px;
          @media (max-width: 767px) {
            font-size: 20px;
          }
        }
        .container_svg {
          @media (max-width: 767px) {
            display: flex;
            justify-content: flex-start;
          }
          .form_svg_lhs {
            @media (max-width: 767px) {
              margin-top: 0;
              margin-right: 15px;
            }
          }
        }
      }
      .mcq_answers {
        h3 {
          text-align: center;
          font-size: 24px;
          @media (max-width: 767px) {
            font-size: 20px;
          }
        }
        .miq_container {
          padding: 0 15px;
        }
        .container_svg {
          display: flex;
          flex-direction: column;
          align-items: center;
          @media (max-width: 767px) {
            flex-direction: unset;
            justify-content: flex-start;
            padding: 0;
          }
        }
        .question_answers {
          min-height: 42px;
          border: 1px dashed black;
          background-color: rgb(240, 240, 240);
          margin: 15px 0 0 0;
          border-radius: 10px;
          overflow: hidden;
          h6 {
            margin: 0;
            text-align: center;
            background-color: var(--very-pale-orange);
            padding: 10px 20px;
            border-radius: 10px;
            cursor: pointer;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 991px) {
              font-size: 14px;
            }
          }
          .formLHS {
            h6 {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              display: block;
              &:focus,
              &:hover {
                color: transparent;
                &:after {
                  content: attr(data-text);
                  overflow: visible;
                  text-overflow: inherit;
                  background: #fff;
                  position: absolute;
                  left: auto;
                  top: auto;
                  width: auto;
                  max-width: 20rem;
                  border: 1px solid #eaebec;
                  padding: 0 0.5rem;
                  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
                  white-space: normal;
                  word-wrap: break-word;
                  display: block;
                  color: black;
                  margin-top: -1.25rem;
                }
              }
            }
          }
        }
        .question_ans_right {
          border: 2px dashed var(--strong-cyan) !important;
        }
        .question_ans_wrong {
          border: 2px dashed var(--soft-red) !important;
          opacity: 0.5;
        }
        .opacity_low {
          opacity: 0.5;
        }
        .question_answers_svg {
          min-height: 2.1rem;
          border: 1px dashed black;
          background-color: rgb(240, 240, 240);
          margin: 15px 0 0 0;
          height: 4rem;
          width: 4rem;
          overflow: hidden;
          @media (max-width: 767px) {
            margin: 0 15px 15px 0;
          }
          .answer_svg {
            cursor: pointer;
            background-color: lightgrey;
            height: calc(4rem - 2px);
            width: calc(4rem - 2px);
            svg {
              height: calc(4rem - 2px);
              width: calc(4rem - 2px);
              path,
              rect,
              ellipse,
              circle {
                fill: var(--very-pale-orange);
              }
            }
          }
        }
      }
      .mcq_options {
        border-radius: 0 1rem 1rem 0;
        .container_svg {
          display: flex;
          flex-direction: column;
          align-items: center;
          @media (max-width: 767px) {
            flex-direction: unset;
            justify-content: flex-start;
            padding: 0;
          }
          .question_options {
            @media (max-width: 767px) {
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-start;
            }
            .disable {
              opacity: 0.4;
            }
          }
        }
        .dropbackArea {
          min-height: 15rem;
        }
        h3 {
          text-align: center;
          font-size: 24px;
          @media (max-width: 767px) {
            font-size: 20px;
          }
        }
        .question_answers {
          margin: 15px 0 0 0;
          border-radius: 10px;
          overflow: hidden;
          &.disable {
            // pointer-events: none;
            opacity: 0.4;
          }
        }
        .question_options {
          .disable {
            // pointer-events: none;
            opacity: 0.4;
          }
          h6 {
            text-align: center;
            border-radius: 0.5rem;
            padding: 9px 20px;
            margin: 15px 0 0 0;
            background-color: var(--very-pale-orange);
            cursor: pointer;
            min-height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid var(--very-pale-orange);
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
          }
          .formLHS {
            h6 {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              display: block;
              &:focus,
              &:hover {
                color: transparent;
                &:after {
                  content: attr(data-text);
                  overflow: visible;
                  text-overflow: inherit;
                  background: #fff;
                  position: absolute;
                  left: auto;
                  top: auto;
                  width: auto;
                  max-width: 20rem;
                  border: 1px solid #eaebec;
                  padding: 0 0.5rem;
                  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
                  white-space: normal;
                  word-wrap: break-word;
                  display: block;
                  color: black;
                  margin-top: -1.25rem;
                }
              }
            }
          }
          .Rhs_svg {
            background-color: lightgrey;
            max-height: 4rem;
            max-width: 4rem;
            margin: 15px 0 0 0;
            cursor: pointer;
            @media (max-width: 767px) {
              margin: 0 15px 15px 0;
            }
            img {
              max-height: 4rem;
              max-width: 4rem;
            }
          }
        }
        // .question_options {
        // 	margin: 0;
        // 	text-align: center;
        // 	background-color: var(--very-pale-orange);
        // 	padding: 10px 20px;
        // 	cursor: pointer;
        // 	font-weight: 400;
        // 	font-size: 16px;
        // 	line-height: 20px;
        // 	display: flex;
        // 	align-items: center;
        // 	justify-content: center;
        // 	.Rhs_svg {
        // 		background-color: lightgrey;
        // 		height: 4rem;
        // 		width: 4rem;
        // 		margin: 15px 0 0 0;
        // 		cursor: pointer;
        // 		@media (max-width: 767px) {
        // 			margin: 0 15px 15px 0;
        // 		}
        // 		img {
        // 			height: 4rem;
        // 			width: 4rem;
        // 		}
        // 	}
        // 	@media (max-width: 991px) {
        // 		font-size: 14px;
        // 	}
        // }
      }
    }
    #rhs_svg {
      img {
        max-height: 4rem;
        max-width: 4rem;
        margin-top: 0;
        path,
        rect,
        ellipse,
        circle {
          fill: var(--very-pale-orange);
        }
      }
    }
    #ans_svg {
      img {
        max-height: calc(4rem - 2px);
        max-width: calc(4rem - 2px);
        margin-top: 0;
      }
    }
    &.with_svg {
      .drag_drop_wrapper {
        .mcq_lhs {
          .formLHS {
            h6 {
              min-height: 64px;
              display: flex;
              align-items: center;
            }
          }
        }
        .mcq_options {
          .question_options {
            h6 {
              min-height: 64px;
            }
          }
        }
        .mcq_answers {
          .question_answers {
            min-height: 64px;
            h6 {
              min-height: 62px;
            }
            &.question_ans_right,
            &.question_ans_wrong {
              h6 {
                min-height: 60px;
              }
            }
          }
        }
      }
    }
  }
  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.question_answers_inner {
  position: relative;
  .btn {
    padding: 0;
    border: 1px solid #f44336;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -25px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
    &:hover,
    &:active {
      background-color: transparent !important;
    }
    img {
      width: 10px;
      height: 10px;
      display: block;
      margin: 0;
    }
  }
  .cross-icon-drag-drop {
    background-color: white !important;
    &:hover,
    &:active {
      background-color: #d3d3d3 !important;
    }
  }
}
