.auth_content {
	width: 50%;
	background-color: var(--very-light-blue);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
	position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
	@media (max-width: 767px) {
		width: 100%;
		order: 2;
		position: unset;
	}
	.auth_content_wrapeer {
		max-width: 457px;
		svg {
			width: 100%;
		}
		.auth_logo {
			svg {
				@media (max-width: 991px) {
					height: 32px;
				}
			}
		}
		.auth_title {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 44px;
			margin-bottom: 20px;
			@media (max-width: 767px) {
				margin-top: 24px;
				margin-bottom: 10px;
				display: block;
				text-align: center;
			}
			h2 {
				font-weight: 600;
				font-size: 50px;
				line-height: 59px;
				color: var(--white);
				margin: 0 18px 0 0px;
				@media (max-width: 991px) {
					font-size: 40px;
					line-height: 49px;
				}
				@media (max-width: 767px) {
					font-size: 30px;
					line-height: 39px;
				}
			}
		}
		.carousel {
			padding-bottom: 124px;
			@media (max-width: 991px) {
				padding-bottom: 74px;
			}
			p {
				font-weight: 500;
				font-size: 18px;
				line-height: 25px;
				color: var(--white);
				text-align: center;
				margin: 0;
				min-height: 50px;
				@media (max-width: 991px) {
					font-size: 16px;
					line-height: 23px;
				}
			}
			.carousel-indicators {
				margin: 0;
				button {
					width: 8px;
					height: 8px;
					background: var(--very-light-gray);
					border-radius: 10px;
					flex: unset;
					padding: 0;
					border: 0;
					opacity: 1;
					transition: all 0.5s ease;
					outline: none;
					&.active {
						width: 26.67px;
						background: var(--very-light-orange);
					}
				}
			}
			.carousel-control-prev {
				display: none;
			}
			.carousel-control-next {
				display: none;
			}
		}
	}
}
