.error_component{
	min-height: calc(100vh - 259px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.error_message_handle {
	margin-bottom: 0;
	padding: 0;
	background: none;
	border: none;
	color: var(--soft-red);
	text-align: center;
}

.top_error_message_handle {
    margin-bottom: 10px;
}

.top_error_message_handle .error_message_handle {
    text-align: left;
}