.alert_message {
	position: absolute;
	top: 20px;
	right: 20px;
	z-index: 99999;
	.alert {
		margin: 0;
		display: flex;
		align-items: center;
		padding: 16px 50px 16px 20px;
		img.check-icon {
			width: 25px;
			height: 25px;
			object-fit: contain;
			background: var(--white);
			border-radius: 50%;
			padding: 5px;
			margin-right: 20px;
		}
		p {
			margin: 0;
		}
		.close-icon {
			position: absolute;
			right: 20px;
			line-height: 0;
			button {
				outline: none;
				border: none;
				padding: 0;
				line-height: 0;
				background: none;
				img {
					max-height: 12px;
				}
			}
		}
	}
}
