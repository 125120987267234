.sections_page {
  min-height: calc(100vh - 46px);
  .loader_section {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .breadcrumbs {
    .breadcrumbs_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .total_score {
        margin: 0;
        font-weight: 600;
      }
      .form-check-input {
        border-color: #0d6efd;
      }
      .form-check-label {
        color: #0d6efd;
      }
    }
    ul {
      padding: 0;
      list-style-type: none;
      margin: 20px 0 22px 0;
      display: flex;
      flex-wrap: wrap;
      li {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        color: var(--very-light-orange);
        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 26px;
        }
        &.active {
          color: #888;
        }
        a {
          color: var(--very-light-orange);
          text-decoration: none;
          transition: 0.3s all;
          &:hover {
            opacity: 0.8;
          }
        }
        .icon {
          margin: -2px 12px 0;
        }
      }
    }
  }
  .banner_section {
    background: linear-gradient(
      180deg,
      rgb(255 150 104 / 10%) 0%,
      rgb(130 182 255 / 10%) 100%
    );
    mix-blend-mode: normal;
    padding: 7.5rem 0 3.375rem;
    position: relative;
    text-align: center;
    &:before {
      content: "";
      background-image: url("../../assets/images/banner-overlay.png");
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    h1 {
      font-weight: 700;
      font-size: 3rem;
      line-height: 3.5rem;
      color: var(--very-light-orange);
      margin: 0;
      position: relative;
      @media (min-width: 1200px) {
        font-size: 4.0625rem;
        line-height: 4.75rem;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        font-size: 4rem;
        line-height: 4.25rem;
      }
      @media (max-width: 767px) {
        font-size: 2rem;
        line-height: 2.5rem;
      }
      span {
        color: var(--very-light-orange);
        display: block;
      }
    }
    p {
      .btn {
        color: var(--very-light-orange);
        margin-left: 8px;
        padding: 0;
        font-size: 16px;
        font-weight: 500;
        background: transparent;
        border: none;
      }
    }
  }
  .sections_list {
    padding: 0 5rem 5rem 5rem;
    @media (max-width: 991px) {
      padding: 0 2.5rem 2.5rem 2.5rem;
    }
    @media (max-width: 767px) {
      padding: 0 0 50px 0;
    }
    .accordion-item {
      margin-bottom: 30px;
      border-radius: 0;
      border: none;
      border-radius: 60px 20px 20px 60px;
      &.active_Accordion {
        border-radius: 60px 20px 20px 20px;
        box-shadow: 0px 20px 50px -20px rgba(80, 80, 80, 0.2);
      }
      background-color: var(--white);
      .accordion-header {
        .accordion-button {
          position: relative;
          display: block;
          padding: 0;
          background-color: transparent;
          box-shadow: none;
          &:focus {
            outline: none;
            box-shadow: none;
            background-color: transparent;
          }
          .number {
            position: absolute;
            left: 0px;
            top: 0;
            width: 120px;
            height: 120px;
            background: var(--very-light-blue);
            font-style: italic;
            font-weight: 200;
            font-size: 64px;
            line-height: 75px;
            color: var(--white);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            @media (max-width: 991px) {
              font-size: 54px;
              line-height: 65px;
            }
            @media (max-width: 767px) {
              width: 40px;
              height: 40px;
              font-size: 22px;
              top: 4px;
              background: var(--very-light-blue);
              font-weight: 500;
              color: var(--white);
              left: 0;
              line-height: 22px;
            }
          }
          .accordion_header_content {
            max-width: calc(100% - 60px);
            margin-left: auto;
            @media (max-width: 767px) {
              max-width: 100%;
            }
            h3 {
              background: var(--very-light-blue);
              border-radius: 0px 20px 0px 0px;
              font-weight: 500;
              font-size: 24px;
              line-height: 28px;
              color: var(--white);
              padding: 16px 80px 16px 80px;
              margin: 0;
              @media (max-width: 991px) {
                font-size: 20px;
                line-height: 24px;
                padding: 18px 60px 18px 60px;
              }
              @media (max-width: 767px) {
                padding: 13px 50px 13px 50px;
                font-size: 16px;
                line-height: 20px;
                border-radius: 20px 20px 0 0;
              }
            }
            .attempted {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              background-color: #e6e6e6;
              padding-left: 80px;
              min-height: 60px;
              max-height: 60px;
              @media (max-width: 991px) {
                padding-left: 60px;
              }
              @media (max-width: 767px) {
                padding-left: 0;
                min-height: unset;
              }
              .inner {
                display: flex;
                position: relative;
                width: 100%;
                .dots {
                  display: inline-block;
                  overflow: auto;
                  white-space: nowrap;
                  padding: 16px 0 11px;
                  scrollbar-width: 2px;
                  min-height: 51.19px;
                  width: calc(100% - 150px);
                  @media (max-width: 991px) {
                    padding: 18px 0 13px;
                  }
                  @media (max-width: 767px) {
                    padding: 13px 0;
                    min-height: unset;
                    display: none;
                  }
                  &::-webkit-scrollbar {
                    width: 2px;
                    height: 8px;
                  }
                  &::-webkit-scrollbar-corner {
                    background-color: transparent;
                    border-radius: 12px;
                  }
                  &::-webkit-scrollbar-track {
                    background-color: transparent;
                    border-radius: 12px;
                    background-clip: content-box;
                    border: 2px solid transparent;
                  }
                  &::-webkit-scrollbar-thumb {
                    background-color: var(--very-light-blue);
                    border-radius: 12px;
                  }
                }
                .attempted_number {
                  display: inline-block;
                  margin: 0 13px 0 46px;
                  font-weight: 400;
                  font-size: 18px;
                  color: var(--very-light-blue);
                  position: absolute;
                  top: -3px;
                  right: 0;
                  bottom: 0;
                  display: flex;
                  align-items: center;
                  @media (max-width: 767px) {
                    font-size: 16px;
                    margin: 0 13px 0 50px;
                    position: unset;
                    padding: 13px 0;
                  }
                }
              }
              span {
                width: 20px;
                height: 20px;
                display: inline-block;
                background-color: var(--white);
                border-radius: 100%;
                margin: 0 13px 0 0;
                @media (max-width: 991px) {
                  width: 15px;
                  height: 15px;
                }
                @media (max-width: 767px) {
                  width: 10px;
                  height: 10px;
                }
                &.active {
                  background: rgba(130, 182, 255, 0.5);
                }
                &.unattempted {
                  background: white;
                }
                &.right {
                  background: var(--strong-cyan);
                }
                &.wrong {
                  background: rgba(253, 93, 93, 0.5);
                }
                &.question_attempted {
                  background: rgba(130, 182, 255, 0.5);
                }
              }
              h5 {
                font-weight: 400;
                font-size: 18px;
                line-height: 21px;
                color: var(--very-light-blue);
                margin: 0 0 0 40px;
              }
            }
          }
          &:after {
            position: absolute;
            width: 15px;
            height: 29px;
            right: 30px;
            top: 15px;
            background-size: contain;
            background-image: url("../../assets/images/icons/arrow-icon.svg");
            @media (max-width: 991px) {
              width: 10px;
              height: 22px;
              top: 14px;
            }
          }
          .lock_icon {
            position: absolute;
            right: 18px;
            top: 10px;
            @media (max-width: 991px) {
              top: 8px;
              right: 23px;
            }
            @media (max-width: 767px) {
              top: 4px;
            }
            svg {
              @media (max-width: 991px) {
                width: 22px;
              }
              path {
                stroke: var(--white);
              }
            }
          }
          &:not(.collapsed)::after {
            transform: rotate(90deg);
          }
        }
        &.section_lock {
          pointer-events: none;
          .accordion-button {
            &:after {
              display: none;
            }
          }
        }
      }
      .accordion-body {
        padding: 40px 30px;
        @media (max-width: 991px) {
          padding: 40px 15px 20px;
        }
        @media (max-width: 767px) {
          padding: 20px 15px;
        }
        img {
          max-width: 100%;
          object-fit: contain;
        }
        .mixed_english_reading_group {
          .mixed_english_reading_box {
            background: #f7f7f7;
            border-radius: 10px;
            padding: 20px;
            max-height: 365px;
            overflow: auto;
            margin-bottom: 36px;
            scrollbar-color: var(--very-light-blue) transparent;
            scrollbar-width: 8px;
            &::-webkit-scrollbar {
              width: 8px;
              height: 8px;
            }
            &::-webkit-scrollbar-corner {
              background-color: transparent;
              border-radius: 12px;
            }
            &::-webkit-scrollbar-track {
              background-color: transparent;
              border-radius: 12px;
              background-clip: content-box;
              border: 2px solid transparent;
            }
            &::-webkit-scrollbar-thumb {
              background-color: var(--very-light-blue);
              border-radius: 12px;
            }
            h4 {
              font-weight: 600;
              font-size: 24px;
              line-height: 28px;
              color: var(--mostly-black);
              margin-bottom: 15px;
              @media (max-width: 767px) {
                font-size: 20px;
                line-height: 24px;
              }
              p {
                font-weight: inherit;
                font-size: inherit;
                line-height: inherit;
                margin-bottom: inherit;
              }
            }
            p {
              font-weight: 400;
              font-size: 18px;
              line-height: 21px;
              color: var(--mostly-black);
              margin-bottom: 30px;
              @media (max-width: 767px) {
                font-size: 16px;
                line-height: 19px;
                margin-bottom: 15px;
              }
            }
          }
        }
      }
      table {
        width: auto !important;
        border: 1px solid #000;
        th,
        td {
          padding: 10px;
          border: 1px solid #000;
          @media (max-width: 767px) {
            padding: 5px;
          }
        }
      }
    }
    .grad_submit_btn {
      background: var(--very-light-orange);
      color: var(--white);
      border-radius: 40px;
      box-shadow: 0px 7px 12px rgba(255, 150, 104, 0.5);
      border: none;
      padding: 17px 48px;
      margin-left: auto;
      margin-right: 0;
      display: block;
      @media (max-width: 767px) {
        padding: 10px 18px;
      }
      h5 {
        margin-bottom: 0;
        @media (max-width: 767px) {
          font-size: 1.125rem;
        }
      }
    }
    .paid_button_div {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 52px;
      @media (max-width: 767px) {
        margin-top: 30px;
      }
      .mobile_text {
        display: none;
        width: 100%;
        text-align: right;
        @media (max-width: 767px) {
          display: block;
          text-align: center;
          margin-top: 10px;
          font-size: 14px;
          margin-bottom: 0;
          font-weight: 400;
        }
      }
      p {
        font-size: 1rem;
        font-weight: lighter;
        margin: 0;
      }
      button {
        &.btn-primary {
          display: flex;
          align-items: center;
          background: var(--very-light-orange);
          border-radius: 40px;
          color: var(--white);
          border: none;
          padding: 16.5px 30px 16.5px 30px;
          transition: all 0.5s ease;
          font-weight: 600;
          font-size: 22px;
          @media (max-width: 767px) {
            margin: 20px auto 0;
            padding: 12px 20px;
            font-size: 16px;
            width: 100%;
            justify-content: center;
          }
          &:hover {
            transform: translateY(-3px);
          }
          &.save_draft {
            background: #ca82ff;
            padding: 16.5px 60px 16.5px 60px;
            @media (max-width: 767px) {
              padding: 14px 20px;
            }
          }
          h5 {
            font-size: 22px;
            line-height: 26px;
            margin: 0 0 2px 0;
            font-weight: 600;
            @media (max-width: 767px) {
              font-size: 16px;
            }
          }
          p {
            font-weight: 400;
            line-height: normal;
            font-size: 16px;
            @media (max-width: 767px) {
              display: none;
            }
          }
        }
      }
    }
    .unpaid_button_div {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      margin-top: 72px;
      @media (max-width: 767px) {
        margin-top: 42px;
        justify-content: center;
      }
      .mobile_text {
        display: none;
        width: 100%;
        text-align: right;
        @media (max-width: 767px) {
          display: block;
          text-align: center;
          margin-top: 10px;
          font-size: 14px;
          margin-bottom: 0;
          font-weight: 400;
        }
      }
      .unpaid_btn {
        display: flex;
        align-items: center;
        background: var(--very-light-orange);
        // box-shadow: 0px 7px 12px rgb(255 150 104 / 50%);
        border-radius: 40px;
        color: var(--white);
        border: none;
        padding: 16.5px 30px 16.5px 50px;
        transition: all 0.5s ease;
        &:hover {
          transform: translateY(-3px);
        }
        @media (max-width: 767px) {
          font-size: 16px;
          line-height: 22px;
          padding: 6px 20px;
          width: 100%;
          justify-content: center;
        }
        h5 {
          font-size: 22px;
          line-height: 26px;
          margin: 0 0 2px 0;
          @media (max-width: 767px) {
            font-size: 18px;
            line-height: 22px;
          }
        }
        svg {
          margin-left: 1rem;
          @media (max-width: 767px) {
            width: 24px;
            margin-left: 5px;
          }
          path {
            stroke: var(--white);
          }
        }
        p {
          font-size: 16px;
          font-weight: 400;
          margin: 0;
          line-height: 19px;
          @media (max-width: 767px) {
            font-size: 14px;
            line-height: 20px;
            display: none;
          }
        }
      }
    }
    .last_submission_time {
      margin: 50px 0 0 0;
      @media (max-width: 767px) {
        margin: 20px 0 0 0;
      }
      p {
        font-size: 16px;
        margin: 0 0 2px 0;
        line-height: normal;
        span {
          color: #ff0000;
        }
        @media (max-width: 767px) {
          font-size: 14px;
          text-align: center;
        }
      }
    }
  }
}
.section-error {
  min-height: calc(100vh - 46px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.group:not(:last-child) {
  margin-bottom: 50px;
}

.group .group_title {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: var(--mostly-black);
  margin-bottom: 15px;
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 24px;
  }
}

.group .group_description {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: var(--mostly-black);
  margin-bottom: 32px;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 16px;
  }
}

.remove_abcd {
  list-style-type: none;
}
.show_as_abcd {
  margin-right: 30px;
}
.align_h {
  display: flex;
}
ol.align_h .item {
  margin-right: 10px;
}

.submit_modal {
  .modal-dialog {
    max-width: 600px;
  }
  .modal-body {
    text-align: center;
    padding-top: 40px;
  }
  .modal-footer {
    border: none;
    justify-content: center;
    padding-bottom: 40px;
    .btn {
      width: 45%;
      margin: 0;
      background: #ff9668;
      color: #fff;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 180px;
      margin: 0 10px;
      &.save_draft {
        background-color: #ca82ff;
      }
    }
  }
}

.cs-tooltip .tooltip-inner {
  max-width: max-content;
}
