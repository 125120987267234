.text-12px {
  font-size: 12px !important;
  line-height: 18px;
}
.font-20px {
  font-size: 20px !important;
  line-height: 28px;
}
.text-orange {
  color: #ff9668 !important;
}
.practice_page {
  .down-arrow {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2;
    width: 40px;
    height: 70px;
    span {
      position: absolute;
      top: 0;
      left: 50%;
      width: 24px;
      height: 24px;
      margin-left: -12px;
      border-left: 2px solid #ca82ff;
      border-bottom: 2px solid #ca82ff;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-animation: sdb07 2s infinite;
      animation: sdb07 2s infinite;
      opacity: 0;
      box-sizing: border-box;
      &:nth-of-type(1) {
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
      }
      &:nth-of-type(2) {
        top: 16px;
        -webkit-animation-delay: 0.15s;
        animation-delay: 0.15s;
      }
      &:nth-of-type(3) {
        top: 32px;
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
      }
    }
  }
  .loader_section {
    min-height: calc(100vh - 46px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .banner_section {
    background: linear-gradient(
      180deg,
      rgb(255 150 104 / 10%) 0%,
      rgb(202 130 255 / 10%) 100%
    );
    mix-blend-mode: normal;
    padding-top: 5.6875rem;
    position: relative;
    @media (max-width: 991px) {
      max-height: 100vh;
    }
    &:before {
      content: "";
      background-image: url("../../assets/images/home-banner-overlay.png");
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .banner_wrapper {
      padding: 40px 0;
      position: relative;
      @media (min-width: 992px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 650px;
      }
      @media (max-width: 991px) {
        min-height: calc(100vh - 5.6875rem);
      }
      .banner_image {
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 28.375rem;
        @media (max-width: 991px) {
          display: none;
        }
      }
      .banner_content {
        @media (min-width: 1200px) {
          max-width: 668px;
        }
        @media (min-width: 992px) and (max-width: 1199px) {
          max-width: 460px;
        }
        h1 {
          font-weight: 700;
          font-size: 3rem;
          line-height: 3.5rem;
          color: var(--mostly-black);
          margin: 0 0 30px;
          @media (min-width: 1200px) {
            font-size: 5.3125rem;
            line-height: 6.25rem;
          }
          @media (min-width: 992px) and (max-width: 1199px) {
            font-size: 4.3125rem;
            line-height: 5.25rem;
          }
          @media (max-width: 767px) {
            margin-bottom: 20px;
          }
          color: var(--very-light-orange);
        }
        h2 {
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.7rem;
          color: var(--mostly-black);
          margin: 0 0 30px;
          color: grey;
        }
        p {
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.75rem;
          color: var(--mostly-black);
          margin: 10px 0 0;
          @media (min-width: 768px) {
            margin-top: 15px;
          }
        }
      }
    }
  }
  .questions_set_section {
    padding: 70px 0;
    .questions_set_box {
      h2 {
        font-weight: 600;
        font-size: 2rem;
        line-height: 2.375rem;
        color: var(--mostly-black);
        margin: 0;
        @media (max-width: 767px) {
          font-size: 1.5rem;
          line-height: 1.2;
        }
      }
      &:not(:last-child) {
        margin-bottom: 33px;
      }
      .questions_set_list {
        margin-top: 2.0625rem;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        .questions_set_item {
          background: #ca82ff;
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          width: 100%;
          margin: 0 0 20px;
          text-align: center;
          transition: all 0.5s ease;
          transform: scale(1);
          cursor: pointer;
          padding: 0 10px;
          position: relative;
          overflow: hidden;
          @media (max-width: 767px) {
            padding: 20px 10px 20px;
          }
          .timed_icon {
            position: absolute;
            top: 0px;
            left: 0px;
            svg {
              width: 40px;
              height: auto;
              @media (max-width: 1199px) {
                width: 30px;
              }
              @media (max-width: 767px) {
                width: 20px;
              }
              path {
                stroke: powderblue;
              }
            }
          }
          @media (min-width: 1400px) {
            width: 200px;
            height: 200px;
            margin-bottom: 44px;
          }
          @media (min-width: 1200px) and (max-width: 1399px) {
            width: 199px;
            height: 199px;
            margin-bottom: 30px;
          }
          @media (min-width: 992px) and (max-width: 1199px) {
            width: 163px;
            height: 163px;
            margin-bottom: 30px;
          }
          @media (min-width: 768px) and (max-width: 991px) {
            width: 140px;
            height: 140px;
            margin-bottom: 30px;
          }
          @media (min-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          &:not(:nth-child(5n + 1)) {
            @media (min-width: 1400px) {
              margin-left: 44px;
            }
            @media (min-width: 1200px) and (max-width: 1399px) {
              margin-left: 30px;
            }
            @media (min-width: 992px) and (max-width: 1199px) {
              margin-left: 30px;
            }
          }
          &:not(:nth-child(4n + 1)) {
            @media (min-width: 768px) and (max-width: 991px) {
              margin-left: 30px;
            }
          }
          &:hover {
            box-shadow: 0px 4px 40px rgb(0 0 0 / 30%);
            transition: all 0.5s ease;
            transform: scale(1.05);
          }
          .promo-label {
            position: absolute;
            top: -10px;
            right: -27px;
            width: 85px;
            text-align: center;
            transform: rotate(45deg);
            background: #e2e2e2;
            padding: 5px;
            color: var(--very-light-orange);
            font-weight: 600;
            font-size: 30px;
            line-height: 35px;
            span {
              transform: rotate(-45deg);
              display: block;
              position: relative;
              top: 9px;
              right: -5px;
            }
            &.draft-label {
              background: var(--very-light-orange);
              color: #fff;
            }
          }
          .questions_set {
            max-width: 100%;
            @media (max-width: 767px) {
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }
            img {
              max-width: 60px;
              height: auto;
              @media (max-width: 1199px) {
                max-width: 50px;
              }
              @media (max-width: 767px) {
                max-width: 30px;
              }
            }
            svg {
              width: 72px;
              height: 72px;
              @media (max-width: 1199px) {
                transform: scale(0.6);
              }
              path {
                fill: #fff !important;
              }
            }
            h4 {
              font-weight: 600;
              font-size: 1.625rem;
              line-height: 1.875rem;
              color: var(--white);
              margin: 1rem 0 0;
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              @media (max-width: 1199px) {
                font-size: 1.225rem;
                line-height: 1.475rem;
              }
              @media (max-width: 767px) {
                font-weight: 500;
                margin-top: 0;
                margin-left: 10px;
                font-size: 1rem;
                max-width: calc(100% - 60px);
              }
            }
          }
        }
      }
    }
  }
  .error_message {
    color: var(--soft-red);
  }
}
.practice-error {
  min-height: calc(100vh - 46px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn.btn-weekly {
  background: #cfe2ff !important;
  color: #000;
  font-size: 18px;
  padding-inline: 20px;
  text-decoration: none !important;
  padding-left: 5px;
  padding-right: 5px;
}
.btn.btn-on-demand {
  background: var(--very-light-orange) !important;
  color: #fff;
  font-size: 16px;
  padding-inline: 20px;
  text-decoration: none !important;
}
.btn.btn-on-demand.disabled,
.btn.btn-adaptive.disabled,
.btn.btn-on-demand:disabled,
.btn.btn-adaptive:disabled {
  border: 0;
  color: #fff;
  opacity: 0.4;
}
button.btn.btn-weekly.disabled,
button.btn.btn-weekly:disabled {
  opacity: 0.4;
}
.btn.btn-on-demand > img,
.btn.btn-adaptive > img {
  filter: invert(1);
  position: relative;
  top: -2px;
}
.btn.btn-adaptive {
  background: var(--purple) !important;
  color: #fff;
  font-size: 16px;
  padding-inline: 20px;
  // border-color: #000;
  text-decoration: none !important;
}
.subject-row .btn.disabled {
  opacity: 0.4;
  background: #c9c9c9 !important;
  cursor: crosshair;
}
.cs-form-checkbox input.form-check-input {
  width: 23.98px;
  height: 23.98px;
  border-color: gray;
  box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.4);
}
.cs-form-checkbox .form-check-input:checked {
  background-color: var(--purple);
  border-color: var(--purple) !important;
}
.cs-modal {
  .cs-switch .form-check-input {
    margin: 0;
    width: 3rem;
    height: 25px;
    box-shadow: none !important;
  }
  .mw-600px {
    max-width: 600px;
  }
  .cs-modal-heading {
    position: relative;
    justify-content: center;
    padding-inline: 35px;
    padding-block: 30px 0;
    border: 0;
    button.btn-close {
      position: absolute;
      left: 10px;
      top: 10px;
      margin: 0 !important;
    }
  }
  .timed_modal_info_wrap .timed_modal_info {
    h5 {
      font-size: 14px;
      font-weight: 600;
      margin: 0 0 20px;
    }
    h4 {
      font-size: 42px;
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 1;
    }
  }
}

// .accordion-inner .subject-row:last-child {
//     border-bottom: 0 !important;
// }
.bg-lightblue {
  background-color: #0018c514 !important;
}
.bg-lightgreen {
  background-color: #baffcd !important;
}
// .cs-accordion .accordion-item {
//     border-color: #212529;
// }
.big-star {
  color: #ff9668;
  font-size: 34px;
  display: inline-block;
  vertical-align: middle;
  line-height: 22px;
  height: 18px;
  float: right;
}
.cs-outline-btns > button,
.btn-blue,
.btn-ouline-orange {
  padding: 6px 10px;
  line-height: 18px;
  font-size: 14px;
  background-color: #fff !important;
  border: 1px solid #000 !important;
  color: #000;
  border-radius: 6px;
  min-width: 90px;
}
.cs-outline-btns > button {
  background-color: #fff !important;
  border: 1px solid var(--purple) !important;
  color: var(--purple) !important;
  padding: 8px 10px 6px;
  max-width: 127px;
  margin: auto;
}
.btn-ouline-orange {
  background-color: #fff !important;
  border: 1px solid var(--very-light-orange) !important;
  color: var(--very-light-orange) !important;
}
.btn-blue {
  font-size: 16px;
  padding: 8px 15px;
}
.btn-blue {
  background-color: #cfe2ff !important;
  border-color: #000 !important;
  color: #000;
}
.btn-ouline-orange:hover,
.btn-ouline-orange.active {
  background-color: var(--very-light-orange) !important;
  border-color: var(--very-light-orange) !important;
  color: #fff !important;
}
.cs-outline-btns > button:hover,
.cs-outline-btns > button.active {
  background-color: var(--purple) !important;
  border-color: var(--purple) !important;
  color: #fff !important;
}
.cs-tooltip .tooltip-inner {
  width: 340px;
  word-wrap: break-word;
  white-space: normal;
  padding: 10;
  border-radius: 4px;
}
.btn.btn-weekly,
.btn.btn-on-demand,
.btn.btn-adaptive {
  min-width: 180px;
}
// .btn.btn-on-demand {
//   min-width: 180px;
// }
// .btn.btn-adaptive {
//   min-width: 151px;
// }
.cs-switch .form-check-input:checked {
  background-color: #82b6ff !important;
  border-color: #82b6ff !important;
}
.cs-accordion,
.cs-accordion2 {
  .accordion-item {
    border: 0;
    margin-bottom: 30px;
    background: #fff;

    border-radius: 15px !important;
  }
  .accordion-header > {
    button {
      background: #fff;
      border-radius: 0 20px 0 0;
      color: #000;
      font-size: 26px;
      font-weight: 500;
      line-height: 28px;
      margin: 0;
      padding: 16px 50px 16px 20px;
      border-radius: 15px !important;
      border: 0 !important;
      box-shadow: none !important;
      &:not(.collapsed) {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      &::after {
        width: 34px;
        height: 34px;
        background-size: 34px;
        filter: grayscale(1);
        opacity: 1;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        background-image: var(--bs-accordion-btn-active-icon);
      }
      &:not(.collapsed)::after {
        transform: translateY(-50%) rotate(0deg) !important;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .btn.btn-weekly {
    min-width: 95px;
  }
  .btn.btn-on-demand {
    min-width: 136px;
  }
  .btn.btn-adaptive {
    min-width: 115px;
  }
}
@media (max-width: 991.98px) and (min-width: 768px) {
  .btn.btn-weekly,
  .btn.btn-on-demand,
  .btn.btn-adaptive {
    font-size: 14px;
    padding-inline: 10px;
  }
  .cs-switch-modes {
    font-size: 18px !important;
  }
  body .border-mobile-none {
    border: none !important;
  }
  body .border-bottom-mob {
    border-bottom: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
}
@media (min-width: 768px) {
  .w-third-tab {
    width: calc(33.33% - 6px);
  }
}
@media (max-width: 767.98px) {
  .min-w-md-25px {
    min-width: 25px;
    text-align: center;
  }

  body .border-mobile-none {
    border: none !important;
  }
  body .border-bottom-mob {
    border-bottom: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .btn.btn-weekly,
  .btn.btn-on-demand,
  .btn.btn-adaptive {
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.timed_modal_info_wrap {
  text-align: center;
  text-align: center;
  max-width: 400px;
  margin: auto;
  .timed_modal_info {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    h4 {
      color: var(--very-light-blue);
      font-size: 1.6rem;
      margin-bottom: 0;
    }
    h5 {
      color: var(--very-light-blue);
      font-size: 1.2rem;
      margin-bottom: 0;
    }
    .infinity_time {
      h4 {
        color: #d3d3d3;
      }
      h5 {
        color: #d3d3d3;
      }
    }
  }
  .timed_modal_info_box,
  .infinity_time {
    padding: 0 10px;
    width: 50%;
  }
}
.timed_modal_rules {
  margin-top: 20px;
  border-top: 1px solid #dee2e6;
  padding-top: 10px;
  span {
    font-weight: 600;
    font-size: 1rem;
  }
  ol {
    padding-left: 1rem;
    li {
      font-size: 0.9rem;
      span {
        font-size: 0.9rem;
        font-weight: 500;
        font-style: italic;
        text-decoration: underline;
      }
    }
  }
}

// TIME MODAL
.count_down_time_modal {
  padding: 0;
  .modal-dialog {
    max-width: 700px;
    .modal-content {
      border: none;
      .modal-header {
        display: block;
        position: relative;
        text-align: center;
        border: none;
        padding-bottom: 0;
        .modal-title {
          font-weight: 700;
          margin: 15px 0 0;
        }
        button.btn-close {
          font-size: 14px;
          position: absolute;
          top: 18px;
          left: 15px;
        }
      }
      .modal-body {
        .timed_modal_info {
          h4 {
            font-size: 42px;
            font-weight: 700;
            letter-spacing: 1px;
            line-height: 1;
          }
          h5 {
            font-weight: 600;
            font-size: 14px;
            margin: 0 0 20px;
          }
          .infinity_time {
            h4 {
              font-size: 70px;
              line-height: 42px;
            }
          }
        }
        .timed_modal_rules {
          border: none;
          text-align: center;
          .switch_checkbox_part {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            margin: 8px 0 0 0;
            p {
              margin: 0;
            }
            .switch_part {
              padding-left: 6px;
              .form-check {
                @media (max-width: 767px) {
                  text-align: center;
                  padding: 0;
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: center;
                  min-height: 66px;
                }
                .form-check-input {
                  cursor: pointer;
                  @media (max-width: 767px) {
                    float: none;
                    margin: 0;
                    order: 1;
                  }
                  &:checked {
                    box-shadow: none;
                    background-color: var(--very-light-blue);
                    border-color: var(--very-light-blue);
                  }
                  &[value="false"]:focus {
                    border-color: #dee2e6;
                    background-image: url("../../assets/images/icons/dot-icon.svg");
                    box-shadow: none;
                  }
                }
                label {
                  cursor: pointer;
                  @media (max-width: 767px) {
                    float: none;
                    width: 100%;
                    order: 0;
                    display: block;
                  }
                }
                input[value="false"] + label {
                  padding-left: 38px;
                  @media (max-width: 767px) {
                    padding-left: 0;
                  }
                }
              }
            }
          }
        }
      }
      .modal-footer {
        border: none;
        justify-content: center;
        padding: 24px 16px 32px;
      }
    }
    @media (max-width: 780px) {
      max-width: 500px;
    }
  }
}

@keyframes sdb07 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.hide-timer-img {
  opacity: 0;
  pointer-events: none;
}

.show-timer-img {
  opacity: 1;
  pointer-events: all;
}

span.counting {
  min-width: 27px;
  text-align: right;
}

.loader-ondemand-adaptive {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #ffffffe0;
  border-radius: 20px;
}
/*-----------------------sujata--------------------------------*/
.practice_page .questions_set_section {
  padding: 15px 0 !important;
}
.min-h-auto {
  min-height: auto !important;
}
.check-cs {
  input.form-check-input {
    margin-top: 0;
  }
}
.lh-18px {
  line-height: 18px;
}
.h-24px {
  height: 24px !important;
}
.font-16 {
  font: 16px;
}
.cs-tooltip {
  font-size: 14px !important;
}
.center-content {
  display: flex;
  width: 61%;
  text-align: center;
  margin: 0 auto;
  justify-content: right;
  gap: 10px;
}
.modal-xl {
  --bs-modal-width: 958px !important;
}
.dots {
  border: transparent !important;
  margin: 0px !important;
}
::-webkit-scrollbar {
  height: 4px !important;
  width: 2px !important;
}

.orange-star img {
  filter: invert(46%) sepia(94%) saturate(1793%) hue-rotate(1deg)
    brightness(107%) contrast(101%);
}

.gray-star img {
  filter: grayscale(100%);
}

.button_icon_svg svg {
  width: 25px;
  height: 25px;
}

.button_icon_svg_img {
  width: 25px;
  height: 25px;
}

span.count-pointer-cursor {
  cursor: pointer !important;
}
