.history_section {
  padding-top: 50px;
  .history_wrapper {
    h2 {
      margin: 0;
      font-size: 28px;
      padding-bottom: 30px;
      font-weight: 600;
      @media (max-width: 767px) {
        font-size: 22px;
      }
    }
    .history_chart {
      padding: 30px;
      border-radius: 10px;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
      @media (max-width: 767px) {
        padding: 10px;
      }
    }
  }
}

.a2-subscription {
  text-decoration: none !important;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid var(--purple);
  padding: 6px 20px;
  border-radius: 30px;
  background-color: var(--purple) !important;
  color: #fff !important;
}

li.link-cursor-pointer {
  cursor: pointer;
}
.dash-bord button.accordion-button.collapsed {
  background-color: rgb(223 255 145 / 32%);
  color: #000;
}

.shadow-space {
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
  margin: 0 auto;
  margin-top: 34px;
}

.date-container {
  display: flex;
  align-items: center; /* Align items vertically centered */
  justify-content: flex-start; /* Align items to the left */
  gap: 10px; /* Optional: Adds some space between the elements */
  max-width: 418px;
  margin: auto;
  margin-top: 0px;
}
.app input.form-control {
  border-radius: 17px;
}

.percentage-text {
  display: inline-block;
  width: 30px; /* Adjust width as necessary */
  text-align: right;
  font-family: monospace; /* Ensure consistent character width */
}

.fixed-width-button {
  background-color: green !important;
  color: white;
  width: 100px !important;
  white-space: nowrap !important;
  text-align: "center";
  min-width: 94px;
}

.w-40 {
  width: 40%;
}
.w-20 {
  width: 20%;
}

hr.width-82 {
  width: 82% !important;
}
