.dtq_display {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	&:not(:last-child) {
		margin-bottom: 20px;
	}
	.active {
		path {
			fill: blue;
		}
	}
	.dtq_display_content {
		display: flex;
		align-items: center;
		width: calc(100% - 43px);
		padding-left: 16px;
		@media (max-width: 767px) {
			padding-left: 6px;
		}
		.form-control {
			max-width: 220px;
			height: 40px;
			background: var(--white);
			border: 1px solid var(--light-gray);
			border-radius: 10px;
			font-weight: 500;
			font-size: 18px;
			line-height: 21px;
			color: var(--mostly-black);
			margin-right: 13.5px;
			padding: 10px 20px;
			&:focus {
				outline: none;
				box-shadow: none;
				border: 2px solid var(--light-blue);
			}
			&.question_ans_wrong {
				border: 2px solid var(--soft-red);
			}
			&.question_ans_right {
				border: 2px solid var(--strong-cyan);
			}
		}
		.sound_icon {
			margin-right: 21px;
			cursor: pointer;
			@media (max-width: 767px) {
				margin-right: 0;
			}
			&.active {
				svg {
					path {
						fill: var(--very-light-blue);
					}
				}
			}
			svg {
				@media (max-width: 767px) {
					width: 20px;
					height: 20px;
				}
			}
		}
		.input-tooltip {
			display: flex;
			align-items: center;
			@media (max-width: 767px) {
				display: none;
			}
		}
		.icons {
			display: flex;
			align-items: center;
			.info_icon {
				cursor: pointer;
				margin-right: 15px;
				margin-left: 15px;
				&.blub {
					svg {
						width: 20px;
						path {
							fill: var(--very-light-blue);
						}
					}
				}
			}
		}
	}
}

.mobile_dtq_display {
	display: none;
	@media (max-width: 767px) {
		display: block;
	}
	.input-tooltip {
		.form-control {
			max-width: 100%;
			height: 40px;
			background: var(--white);
			background-color: white;
			border: 1px solid var(--very-light-gray);
			border-radius: 10px;
			font-weight: 500;
			font-size: 18px;
			line-height: 21px;
			color: var(--mostly-black);
			margin-right: 13.5px;
			padding: 10px 20px;
			margin-bottom: 17px;
			@media (max-width: 767px) {
				font-size: 16px;
				line-height: 19px;
			}
			&:focus {
				outline: none;
				box-shadow: none;
				border: 2px solid var(--very-light-blue);
			}
			&.question_ans_wrong {
				border: 2px solid var(--soft-red);
			}
			&.question_ans_right {
				border: 2px solid var(--strong-cyan);
			}
		}
		.answere {
			display: block;
			margin-bottom: 17px;
			border-radius: 4px;
			text-align: center;
			font-size: 14px;
			line-height: 17px;
			&:before {
				content: "";
				border: 17px solid transparent;
				position: absolute;
				left: 0;
				top: -33px;
				border-bottom-color: #b9aaeb;
				right: 0;
				width: 34px;
				margin: auto;
				bottom: unset;
			}
		}
	}
}