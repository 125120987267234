.about_page {
    padding-top: 0px;
    .banner_section {
		background-image: url('../../assets/images/banner_about.jpg');
        background-size: cover;
		padding: 5.438rem 0;
		position: relative;
		text-align: center;
		h1 {
			font-weight: 700;
            font-size: 30px;
            line-height: 1;
			color: #fff;
			margin: 0;
			position: relative;

			@media (min-width: 1200px) {
				font-size: 3rem;
				line-height: 4.75rem;
			}

			@media (min-width: 992px) and (max-width: 1199px) {
				font-size: 3rem;
				line-height: 4.25rem;
			}
		}
        p {
            font-size: 24px;
            color: var(--white);
            margin: 0;
            position: relative;
            @media (max-width: 991px) {
                font-size: 18px;
            }
        }
	}
    h2 {
        font-size: 32px;
        line-height: normal;
        margin: 0 0 30px 0;
        font-weight: 600;
        @media (max-width: 991px) {
            font-size: 26px;
            margin: 0 0 15px 0;
        }
    }
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 34px;
        color: #090E11;
        margin: 0;
        @media (max-width: 991px) {
            font-size: 16px;
        }
    }
    .our_mission {
        padding: 50px 0px;
        @media (max-width: 767px) {
            padding: 30px 0px 30px;
        }
        .our_mission_wrapper {
            background-image: url('../../assets/images/about-us-img.png');
            background-size: cover;
            background-position: bottom;
            border-radius: 20px;
            padding: 30px 30px 175px;
            @media (max-width: 991px) {
                margin: 0px 0 30px;
            }
            h2 {
                text-align: center;
                margin-bottom: 10px;
            }
            p {
                text-align: center;
                margin-bottom: 8px;
            }
        }
    }
    section.message.white-background {
        background: transparent;
    }
    .our_learning {
        background-color:  rgba(130, 182, 255, 0.12);
        padding: 50px 0px;
        .our_learning_wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            
            @media (max-width: 991px) { 
                flex-wrap: wrap;
                flex-direction: column-reverse;
                margin: 0px 0 30px;
            }
        }
        .our_learning_text {
            ul {
                list-style: none;
                padding-left: 0;
                margin-bottom: 20px;
                li {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 34px;
                    color: #090E11;
                    @media (max-width: 991px) {
                        font-size: 16px;
                        line-height: 1.1;
                        margin-bottom: 8px;
                    }
                }
            }
            p {
                margin-top: 30px;
                @media (max-width: 991px) {
                    margin-top: 10px;
                }
            }
        }
        .our_learning_image {
            @media (max-width: 991px) { 
                margin-bottom: 20px;
            }
            img {
                max-width: 460px;
                @media (max-width: 991px) { 
                    max-width: 100%;
                }
            }
            .our_learning_puzzle {
                svg {
                    max-width: 300px;
                    max-height: 300px;
                    @media (max-width: 767px) {
                        width: 150px;
                        height: 150px;
                    }
                }
            }
        }
    }
    .our_philosophy {
        padding: 140px 0px 50px;

        @media (max-width: 991px){
            padding: 50px 0px 20px;
        }
        .our_philosophy_wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media (max-width: 991px) { 
                flex-wrap: wrap;
                flex-direction: column;
                margin: 0px 0 30px;
            }
        }
        .our_philosophy_text {
            width:50%;

            @media (max-width: 991px) {
                width:100%;
            }
            .our_philosophy_text_wrapper{
                transform: translate(-120px, -90px);
                background-color: #fff;
                padding: 70px 50px;
                position: relative;
                box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
                width: calc(100% + 120px);
                ul {
                    list-style: none;
                    padding-left: 0;
                    margin-bottom: 20px;
                    li {
                        font-size: 18px;
                        color: #000;
                        margin: 0;
                        line-height: 1.4;
                        @media (max-width: 991px) {
                            font-size: 16px;
                            line-height: 1.1;
                            margin-bottom: 8px;
                        }
                    }
                }
                p {
                    font-size: 18px;
                    margin-top: 10px;
                    line-height: 1.4;
                    @media (max-width: 991px) {
                        margin-top: 10px;
                    }
                    a {
                        color: var(--purple);
                        text-decoration: none;
                        }
                }
                @media (max-width: 991px) {
                    width: 90%;
                    margin-top: -120px;
                    transform: translate(10%, 0px);
                }
                @media (max-width: 767px) {
                    margin-top: 0px;
                    box-shadow: none;
                    width: 100%;
                    padding: 0;
                    transform: translate(0%, 0px);
                }
            }
        }
        .our_philosophy_image {
            @media (max-width: 1199px) {
                width: 500px;
            }
            @media (max-width: 991px) { 
                margin-bottom: 20px;
                width: 100%;
            }
            img {
                @media (max-width: 991px) { 
                    max-width: 90%;
                }
                @media (max-width: 767px) { 
                    max-width: 100%;
                }
            }
            .our_philosophy_puzzle {
                svg {
                    max-width: 300px;
                    max-height: 300px;
                    @media (max-width: 767px) {
                        width: 150px;
                        height: 150px;
                    }
                }
            }
        }
    }
    .message {
        background: rgba(202, 130, 255, 0.12);
        padding: 50px 0px;
        .message_wrapper {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            @media (max-width: 767px) { 
                flex-direction: column-reverse;
            }
            .message_wrapper_founder {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                margin-right: 75px;
                margin-bottom: 15px;
                @media (max-width: 991px) { 
                    margin-bottom: 20px;
                    width: 100%;
                    margin-right: 50px;
                }
                @media (max-width: 767px) { 
                    margin: 0 auto 20px;
                    margin-top: 50px;
                }
                .profile {
                    max-width: 315px;
                    border-radius: 100% 315px 0px;
                    @media (max-width: 991px){
                        max-width: 250px;
                        border-radius: 100% 250px 0px;
                    }
                    @media (max-width: 767px){
                        max-width: 150px;
                        border-radius: 100% 150px 0px;
                    }
                }
                p {
                    font-weight: 600;
                    margin-top: 14px;
                    font-size: 26px;
                    color: var(--purple);
                    span {
                        color: var(--black);
                    }
                    a {
                        color: var(--purple);
                        text-decoration: none;
                    }
                    @media (max-width: 767px) {
                        font-size: 18px;
                    }
                }
                .our_learning_puzzle {
                    svg {
                        width: 180px;
                        height: 180px;
                        @media (max-width: 767px) {
                            width: 150px;
                            height: 150px;
                        }
                    }
                }
            }
            .message_wrapper_text {
                p {
                    font-size: 17px;
                    line-height: 1.5;
                    margin-bottom: 30px;
                    @media (max-width: 991px) {
                        font-size: 14px;
                        margin-bottom: 14px;
                        width: 100%;
                    }
                    &:last-child {
                        margin-bottom: 0px;
                    }
                    a {
                        color: var(--purple);
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .about_map {
        margin-top: 60px;
        .about_map_wrapper {
            h2 {
                text-align: center;
                margin-bottom: 30px;
            }
        }
        iframe {
            width: 100%;
            height: 370px;
            border: none;
        }
    }
    .about_container {
        min-height: calc(100vh - 259px);
    }

    .animated {
        text-indent: 8px;
        color:red;
    }
    .animated:before {
        content: "a good education";
        animation: topToBottom 5s infinite 0s;
    }
    @keyframes topToBottom {
        0% {
            content: "a good education";
        }
        25% {
            content: "exam preparation";
        }
        50% {
            content: "upskilling";
        }
    }
}
