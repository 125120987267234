@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "~bootstrap/scss/bootstrap";

:root {
	--very-light-orange: #FF9668;
	--very-light-gray: #e0e0e0;
	--light-gray: #979797;
	--gray: #e8e8e8;
	--very-light-blue: #82b6ff;
	--light-blue: #c8dfff;
	--white: #FFFFFF;
	--black: #000000;
	--dark-gray: #909090;
	--mostly-black: #090e11;
	--soft-red: #e15252;
	--strong-cyan: #2DCCA7;
	--very-pale-orange: #ffdfd3;
	--very-soft-blue: #b9aaeb;
	--very-light-yellow: #edff8f;
	--vivid-blue: #2882ff;
	--purple: #CA82FF;
	--green: #84FF9F;
	--twidth: calc(100vw / 60);
	--theight: calc(var(--twidth) / 2);
}

* {
	box-sizing: border-box;
}

body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: 'Work Sans', sans-serif;
	font-size: 16px;
	font-weight: 400;

	@media (max-width: 991px) {
		font-size: 14px;
	}
}

$theme-colors: (
	"info": tomato,
	"danger": teal
);


@media (min-width: 1400px) {
	.container {
		max-width: 1200px;
	}
}

.emptyPage {
	min-height: calc(100vh - 259px);
	display: flex;
	align-items: center;
	justify-content: center;
}

.form-control {
	padding: 10px 20px;
	font-size: 18px;
	line-height: 21px;

	&:focus {
		box-shadow: none;
	}

	@media (max-width: 767px) {
		font-size: 16px;
		line-height: 19px;
	}
}

.form-check-input[type=radio] {
	appearance: none;
	-webkit-appearance: none;
	display: none;

	+label.form-check-label {
		cursor: pointer;
		position: relative;

		&:after {
			content: attr(data-index);
			position: absolute;
			top: 50%;
			left: -40px;
			transform: translateY(-50%);
			width: 30px;
			height: 30px;
			border-radius: 50%;
			border: 1px solid var(--light-gray);
			display: flex;
			align-items: center;
			justify-content: center;

		}

		p {
			margin-bottom: 0;
		}
	}

	@media (max-width: 767px) {
		width: 25px;
		height: 25px;
	}

	&:focus {
		box-shadow: none;
		border: 1px solid var(--light-blue);
	}

	&:checked {
		+label {
			&:after {
				background: var(--light-blue);
			}
		}
	}
}

.form-control[value]:not([value=""]),

.btn-primary {
	--bs-btn-color: #ff9668;
	--bs-btn-bg: #ffffff;
	--bs-btn-border-color: #ffffff;
	--bs-btn-hover-color: #ffffff;
	--bs-btn-hover-bg: #ff9668;
	--bs-btn-hover-border-color: #ff9668;
	--bs-btn-focus-shadow-rgb: 49, 132, 253;
	--bs-btn-active-color: #ffffff;
	--bs-btn-active-bg: #ff9668;
	--bs-btn-active-border-color: #ff9668;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: #ffffff;
	--bs-btn-disabled-bg: #ff9668;
	--bs-btn-disabled-border-color: #ff9668;
}

.content {
	min-height: calc(100vh - 259px);
}

.modal-body {
    overflow: auto;
	max-height: calc(100vh - 240px);
	img {
		max-width: 100%;
	}
	iframe {
		max-width: 100%;
	}
}

// Modal CSS
.modal_design {
	.modal-dialog {
		max-width: 800px;
		padding: 0 12px;

		@media (max-width: 575px) {
			padding: 0;
		}
	}

	.modal-header {
		padding: 33px 35px 27px 30px;
		border: none;

		@media (max-width: 991px) {
			padding: 23px 25px 17px 20px;
		}

		&.not-have-title {
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
			padding: 0;
			z-index: 12;

			+.modal-body {
				padding: 20px;
			}
		}

		.modal-title {
			font-weight: 600;
			font-size: 40px;
			line-height: 47px;
			color: var(--very-light-orange);

			@media (max-width: 991px) {
				font-size: 30px;
				line-height: 37px;
			}

			@media (max-width: 767px) {
				font-size: 22px;
				line-height: 30px;
			}
		}

		button {
			&.btn-close {
				background-size: 22px;
				width: 22px;
				height: 22px;

				@media (max-width: 991px) {
					background-size: 16px;
					width: 16px;
					height: 16px;
				}

				&:focus {
					outline: none;
					box-shadow: none;
					opacity: 0.5;
				}
			}
		}
	}

	.modal-body {
		padding: 0 50px 50px 30px;

		@media (max-width: 991px) {
			padding: 0 40px 40px 20px;
		}

		ul,
		ol {
			padding: 0 0 0 28px;
			margin: 0;

			@media (max-width: 991px) {
				padding: 0 0 0 24px;
			}

			li {
				margin-bottom: 8px;
				font-size: 20px;
				font-weight: 400;
				line-height: 23px;

				@media (max-width: 991px) {
					font-size: 16px;
					line-height: 19px;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		p {
			font-size: 20px;
			font-weight: 400;
			line-height: 23px;

			@media (max-width: 991px) {
				font-size: 16px;
				line-height: 19px;
			}
		}
	}
}

.after_submit_note {
	text-align: center;
}

.question_number {
	width: 43px;
	position: relative;
	text-align: center;
}

.question_number h6 {
	color: var(--mostly-black);
	display: block;
	font-size: 18px;
	font-weight: 500;
	line-height: 21px;
	margin: 0;

	@media (max-width: 767px) {
		font-size: 16px;
	}
}

.question_number .score {
	font-size: 14px;
	color: var(--very-light-orange);
}

.worng_icon {
    font-size: 18px;
    color: #fd5d5d;
    margin: 0;
}

.correct_icon {
    font-size: 18px;
    color: var(--strong-cyan);
    margin: 0;
}

.loader_container {
	height: 90vh;
	display: flex;
	justify-content: center;
	align-items: center;
}


.react-tel-input .country-list .country {
	display: flex;
	align-items: center;
}

.react-tel-input .country-list .flag {
	margin-top: 0;
}

.profile_form .profile_form_input .react-tel-input .form-control {
	padding-left: 50px;
}

.modal-dialog {
	.btn.btn-primary {
		background-color: #ff9668;
		border-radius: var(--bs-btn-border-radius);
		color: #ffffff;
		font-size: var(--bs-btn-font-size);
		font-weight: var(--bs-btn-font-weight);
		line-height: var(--bs-btn-line-height);
		padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
	}

	.btn.btn-danger {
		border-radius: var(--bs-btn-border-radius);
		color: #ffffff;
		border-color: #ffffff;
		font-size: var(--bs-btn-font-size);
		font-weight: var(--bs-btn-font-weight);
		line-height: var(--bs-btn-line-height);
		padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);

		&:hover {
			border-color: var(--bs-btn-hover-border-color);
		}
	}
	.modal-footer {
		justify-content: space-between;
		.btn.btn-danger {
			background-color: #fff;
			border-color: #ff9668;
			color: #ff9668;
		}
	}

	.btn.btn-link {
		text-decoration: none;
		color: #ff9668;
	}

}