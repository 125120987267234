.tos-page {
    .tos-page-content {
        padding: 60px 0;
        // background: #f8f9ff;
        background: #c8dfff8f;

        .question_section {
            .key_value {
                margin: 15px 0;
                .field_name {
                    font-weight: 500;
                    font-size: 1.4rem;
                }
                .field_value {
                    font-size: 1.2rem;
                }
            }
            .question_value {
                color: var(--purple);
                font-weight: 500;
                font-size: 1.4rem;
            }

            padding: 20px 30px;
            background: white;
            border-radius: 20px;
            margin-bottom: 30px;;
            
            .video-btn {
                color: var(--very-light-blue);
                border: 1px solid var(--very-light-blue);
                svg {
                    margin-right: 7px;
                }
                &:hover {
                    background: var(--very-light-blue);
                    color: white;
                }
            }
        }
    }

}


th, td {
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 40px;
  }

  a:link {
    color: blue;
    background-color: transparent;
    text-decoration: none;
  }
  a:visited {
    color: blueviolet;
    background-color: transparent;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }


ul {
    list-style: none;
    padding: 0; /* Optional: Removes default left padding */
    margin: 0;  /* Optional: Removes default top margin */
}

li {
    min-height: 40px; /* Ensures a minimum height for each list item */
    line-height: 1.5; /* Adjusts the spacing between lines */
}