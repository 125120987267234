.faq-page {

    li.header {
        font-weight: bold;
    }
    .faq-page-content {
        margin: 50px 0;
    }
    .toggle-link {
        padding-left: 0;
    }
    .card-body a {
        text-decoration: none;
    }
}