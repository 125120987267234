.privacy-page {
    .privacy-page-content {
        padding: 60px 0;
    }

    .summary-table {
      border: 1px solid black;
      padding: 10px;
    }

    li.header {
        font-weight: bold;
    }
}