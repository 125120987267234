.fib_base_display {
	display: flex;
	flex-wrap: wrap;
	&:not(:last-child) {
		margin-bottom: 30px;
	}
	span {
		display: block;
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		color: var(--mostly-black);
		width: 43px;
		@media (max-width: 767px) {
			font-size: 16px;
			line-height: 19px;
			width: 100%;
		}
	}
	.fib_base_display_text {
		padding-left: 16px;
		width: calc(100% - 43px);
		@media (max-width: 767px) {
			padding: 8px 0 0 0;
			width: 100%;
		}
		.form-control {
			width: 100%;
			background: var(--white);
			border: 1px solid var(--very-light-gray);
			border-radius: 10px;
		}
		h4 {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			font-weight: 400;
			font-size: 18px;
			line-height: 21px;
			color: var(--mostly-black);
			margin-bottom: 15px;
			@media (max-width: 767px) {
				font-size: 16px;
				line-height: 19px;
			}
			.info_icon {
				margin-left: 20px;
				cursor: pointer;
			}
		}
		.fib_base_display_ques {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 0.5rem;
			.fib_base_display_svg {
				margin: 0 5px 5px;
				svg {
					height: 50px;
					width: 50px;
					rect,
					path,
					ellipse {
						fill: var(--very-pale-orange);
					}
				}
				.radio_buttons {
					margin: 0 2rem;
				}
			}
			.fib_base_display_que_svg {
				background-color: #fff;
				// height: 50px;
				// width: 50px;
				margin: 0 0.5rem;
				box-shadow: 3px 3px 10px 0px rgb(0 0 0 / 20%);
				border-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 200px;
				height: 200px;
				&.question_ans_right {
					border: 2px solid var(--strong-cyan);
				}
				&.question_ans_wrong {
					border: 2px solid var(--soft-red);
				}
				svg {
					height: 40px;
					width: 40px;
					rect,
					path,
					ellipse {
						fill: var(--very-pale-orange);
					}
				}
				.radio_buttons {
					margin: 0 2rem;
				}
			}
		}
		.fib_base_display_radio {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-wrap: wrap;
			.form-check {
				position: relative;
				padding: 0;
				margin: 0 7.5px 7.5px;
				.question_ans_right {
					border: 2px solid var(--strong-cyan);
				}
				.question_ans_wrong {
					border: 2px solid var(--soft-red);
				}
				.form-check-label {
					background-color: #cccccc;
					border-radius: 10px;
					position: unset;
					svg {
						height: 50px;
						width: 50px;
						rect,
						path,
						ellipse {
							fill: var(--very-pale-orange);
						}
					}
					&.active{
						background-color: grey;
					}
					&:after {
						display: none;
					}
				}
				
				.form-check-input {
					position: absolute;
					left: 0;
					top: 0;
					margin: 0;
					width: 100%;
					height: 100%;
					opacity: 0;
					cursor: pointer;
					display: block;
				}
			}
		}
	}
}
