.answere {
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: var(--mostly-black);
	padding: 8px 15px;
	background: var(--very-soft-blue);
	border-radius: 1px 4px 4px 1px;
	position: relative;
	margin: 0;
	display: inline-block;
	@media (max-width: 767px) {
		font-size: 14px;
		line-height: 17px;
	}
	&:before {
		content: '';
		border-top: 17px solid transparent;
		border-bottom: 17px solid transparent;
		border-right: 11px solid var(--very-soft-blue);
		position: absolute;
		left: -10px;
		top: 0px;
		bottom: 0;
	}
}
