.footer {
    background: #F6F6F6;
    padding: 40px 0;
    .footer_wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        @media (max-width: 767px) {
            display: block;
            position: relative;
            text-align: center;
            padding-bottom: 40px;
        }
        p {
            margin-top: 20px;
        }
        span {
            display: block;
            margin-top: 20px;
            @media (max-width: 767px) {
                position: absolute;
                bottom: 0;
                margin-top: 0;
                width: 100%;
            }
        }
        .youtube-link {
            display: flex;
            svg {
                width: 24px;
                height: 24px;
                margin-right: 15px;
            }
        }
        .footer_logo {
            width: 30%;
            @media (max-width: 767px) {
                width: 100%;
            }
        }
        .footer_menu {
            width: 18%;
            text-align: left;
            @media (max-width: 1023px) {
                width: 21%;
            }
            @media (max-width: 767px) {
                margin-top: 20px;
                width: 100%;
            }
            h2 {
                font-size: 20px;
                color: var(--mostly-black);
                margin-bottom: 15px;
                @media (max-width: 767px) {
                    font-size: 18px;
                    margin-bottom: 10px;
                }
            }
            a {
                display: block;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.7);
                text-decoration: none;
                margin-top: 5px;
                @media (max-width: 767px) {
                    font-size: 14px;
                }
            }
        }
    }
}