.reset_page {
	display: flex;
	flex-wrap: wrap;
	min-height: 100vh;
	.reset_form {
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px;
		position: relative;
		margin-left: 50%;
		@media (max-width: 767px) {
			width: 100%;
			margin-left: 0;
		}
		.reset_form_wrapper {
			padding-bottom: 0;
			width: 500px;
			@media (max-width: 767px) {
				width: 100%;
			}
			.left_arrow {
				position: absolute;
				left: -32px;
				margin-top: -10px;
				img {
					max-height: 61px;
				}
			}
			h2 {
				font-weight: 600;
				font-size: 32px;
				line-height: 38px;
				color: var(--mostly-black);
				margin-bottom: 20px;
				@media (max-width: 991px) {
					font-size: 26px;
					line-height: 30px;
					margin-bottom: 10px;
				}
			}
			p {
				font-weight: 500;
				font-size: 18px;
				line-height: 25px;
				color: var(--black);
				margin-bottom: 46px;
				@media (max-width: 991px) {
					font-size: 16px;
					line-height: 23px;
					margin-bottom: 26px;
				}
			}
			.row {
				justify-content: space-between;
				margin: 0;
				.main-form-floating {
					width: 47%;
					padding: 0;
					input {
						padding-right: 20px;
					}
				}
			}
			.form_error_handle {
				&.errorHide {
					display: none;
					position: absolute;
					bottom: 80px;
					background: var(--very-light-yellow);
					border-radius: 10px;
					z-index: 999;
					padding: 14px 16px 33px;
					min-width: 74%;
					left: 29px;
					p {
						margin: 0;
						font-weight: 400;
						font-size: 14px;
						line-height: 16px;
						color: var(--black);
						img {
							display: none;
						}
						&.valid {
							img {
								display: inline-block;
								max-height: 12px;
								position: relative;
								top: -3px;
							}
						}
					}
					&:after {
						content: '';
						width: 0;
						height: 0;
						border-left: 15px solid transparent;
						border-right: 15px solid transparent;
						border-top: 25px solid var(--very-light-yellow);
						position: absolute;
						bottom: -16px;
						left: 23px;
					}
				}
				&.errorShow {
					display: block;
					position: absolute;
					bottom: 80px;
					background: var(--very-light-yellow);
					border-radius: 10px;
					z-index: 999;
					padding: 14px 16px 33px;
					min-width: 74%;
					left: 29px;
					p {
						margin: 0;
						font-weight: 400;
						font-size: 14px;
						line-height: 16px;
						color: var(--black);
						img {
							display: none;
						}
						&.valid {
							img {
								display: inline-block;
								max-height: 12px;
								position: relative;
								top: -3px;
							}
						}
					}
					&:after {
						content: '';
						width: 0;
						height: 0;
						border-left: 15px solid transparent;
						border-right: 15px solid transparent;
						border-top: 25px solid var(--very-light-yellow);
						position: absolute;
						bottom: -16px;
						left: 23px;
					}
				}
			}
			.main-form-floating {
				margin-bottom: 30px;
				span.icon {
					position: absolute;
					top: 20px;
					bottom: 0;
					left: 25px;
					@media (max-width: 991px) {
						top: 14px;
					}
					img {
						max-width: 20px;
						@media (max-width: 991px) {
							max-width: 16px;
						}
					}
				}
				.correction_icon {
					position: absolute;
					top: 50%;
					bottom: 0;
					right: 50px;
					max-height: 15px;
					line-height: 0;
					transform: translateY(-50%);
					img {
						max-height: 14px;
						display: block;
					}
					svg {
						padding-left: 5px;
					}
				}
				.correction_icon + .form-control + .eye-icon {
					right: 78px;
				}
				span.eye-icon {
					position: absolute;
					top: 50%;
					bottom: 0;
					right: 50px;
					cursor: pointer;
					max-height: 12px;
					line-height: 0;
					transform: translateY(-50%);
					img {
						max-width: 18px;
					}
				}
				span.info-icon {
					position: absolute;
					top: 50%;
					bottom: 0;
					right: 20px;
					cursor: pointer;
					max-height: 15px;
					line-height: 0;
					transform: translateY(-50%);
					img {
						max-width: 15px;
					}
					&:hover + .form_error_handle {
						display: block;
					}
				}
			}
			input {
				height: 65px;
				background: #f7f7f7;
				border-radius: 10px;
				border: none;
				font-weight: 500;
				font-size: 18px;
				line-height: 21px;
				color: var(--mostly-black);
				padding: 20px 80px 0 60px;
				@media (max-width: 991px) {
					height: 50px;
					font-size: 16px;
					line-height: 19px;
				}
				&:focus {
					outline: none;
					box-shadow: none;
				}
			}
			.correction_icon + input {
				padding-right: 110px;
			}
			label {
				font-weight: 400;
				font-size: 18px;
				line-height: 21px;
				color: var(--mostly-black);
				padding: 0 40px 0 60px;
				display: flex;
				align-items: center;
				@media (max-width: 991px) {
					font-size: 16px;
					line-height: 19px;
				}
			}
			.btn {
				background: var(--very-light-blue);
				box-shadow: 0px 7px 12px rgba(89, 158, 255, 0.5);
				border-radius: 10px;
				border: none;
				height: 65px;
				width: 100%;
				font-weight: 600;
				font-size: 22px;
				line-height: 26px;
				color: var(--white);
				transition: all 0.5s ease;
				transform: translateY(0);
				margin-top: 20px;
				margin-bottom: 15px;
				@media (max-width: 991px) {
					height: 50px;
					font-size: 20px;
					line-height: 24px;
					margin-top: 15px;
					margin-bottom: 10px;
				}
				&:hover {
					transform: translateY(-3px);
				}
			}
			.main-form-floating {
				margin-bottom: 30px;
				@media (max-width: 991px) {
					margin-bottom: 15px;
				}
				.error {
					label {
						color: var(--soft-red);
					}
					span.info-icon {
						svg {
							fill: var(--soft-red);
							path {
								fill: var(--soft-red);
							}
						}
					}
				}
			}
			span.forgot {
				display: block;
				text-align: center;
				font-weight: 500;
				font-size: 18px;
				line-height: 21px;
				color: var(--mostly-black);
				margin: 15px 0 47px;
				padding: 0 10px;
				a {
					text-decoration: none;
					color: var(--mostly-black);
					&:hover {
						text-decoration: underline;
					}
				}
				b {
					color: var(--vivid-blue);
					font-weight: 600;
				}
			}
		}
		span.signup {
			display: block;
			text-align: center;
			width: 100%;
			font-weight: 500;
			font-size: 18px;
			line-height: 21px;
			color: var(--black);
			margin-top: 55px;
			@media (max-width: 991px) {
				font-size: 16px;
				line-height: 19px;
				margin-top: 25px;
			}
			b {
				color: var(--vivid-blue);
				font-weight: 600;
			}
			a {
				text-decoration: none;
				color: var(--vivid-blue);
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
.reset_page
	.auth_form
	.auth_form_wrapper
	.form-floating
	> .form-control:not(:placeholder-shown)
	~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
	font-size: 14px;
	line-height: 16px;
	transform: scale(0.85) translateY(-0.6rem) translateX(10px) !important;
	opacity: 1 !important;
	&:after {
		background-color: transparent !important;
	}
}
