.loader_section {
  min-height: calc(100vh - 259px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .edit_profile_error {
    width: 100%;
    color: var(--soft-red);
    text-align: center;
  }
  .update_user_success {
    width: 100%;
    color: green;
    text-align: center;
  }
  .profile_form_wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .profile_form_input {
      width: calc(50% - 8px);
    }
  }
  .profile_form_input {
    margin-bottom: 15px;
    width: 100%;
    span {
      display: block;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 2px;
    }
    .form-control,
    .PhoneInputInput {
      background: var(--white);
      border: 1px solid var(--very-light-gray);
      border-radius: 10px;
      padding: 13px 20px;
      width: 100%;
      margin-right: 0px;
      font-size: 16px;
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: var(--very-light-gray);
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: var(--very-light-gray);
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: var(--very-light-gray);
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: var(--very-light-gray);
      }
    }
    .PhoneInputInput {
      position: relative;

      .PhoneInputCountry {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
  .btn {
    width: calc(50% - 8px);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 8px 10px;
    transition: all 0.5s ease;
    font-weight: 500;
    font-size: 18px;
    margin-top: 20px;
    @media (max-width: 381px) {
      width: calc(50% - 8px);
      font-size: 16px;
    }
    &.btn-primary {
      background: var(--very-light-orange);
      color: var(--white);
    }
  }
}

.profile_page {
  padding-bottom: 60px;
  section {
    > div {
      > div {
        padding-bottom: 0px;
      }
    }
    &:first-child,
    &:last-child {
      > div {
        > div {
          border-bottom: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}

.profile_info_section {
  padding-top: 40px;
  .profile_info_wrapper {
    h2 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0;
      font-size: 28px;
      margin-bottom: 30px;
      span {
        font-weight: 600;
      }
      @media (max-width: 767px) {
        font-size: 22px;
      }
    }
    .profile_info {
      background: rgba(202, 130, 255, 0.12);
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      padding-top: 0px;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
      }
      .edit_icon {
        margin-left: 0px;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        width: 78px;
        height: 78px;
        clip-path: polygon(0 0, 100% 100%, 100% 0);
        background-color: var(--purple);
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        transition-duration: 0.3s;

        &:hover {
          transform: scale(1.1);
        }
        @media (max-width: 767px) {
          top: 300px;
          right: 0px;
        }
        svg {
          width: 22px;
          height: 22px;
          padding-bottom: 6px;
          margin: 13px;
          fill: var(--white);

          @media (max-width: 767px) {
            padding: 0px;
            margin: 13px;
            width: 18px;
            height: 18px;
          }
        }
      }
      .profile_info_image {
        margin-bottom: 0px;
        position: relative;
        width: 290px;
        @media (max-width: 991px) {
          width: 200px;
        }
        @media (max-width: 767px) {
          width: 100%;
          margin: 0;
          max-width: 300px;
        }
        img {
          width: 290px;
          height: 290px;
          object-fit: cover;
          @media (max-width: 991px) {
            width: 200px;
            height: 200px;
            margin: 0px;
            border-radius: 10px 0px 0px 10px;
          }
          @media (max-width: 767px) {
            margin: 0px;
            width: 100%;
            max-width: 300px;
            height: 300px;
            border-radius: 10px 10px 0px 0px;
          }
        }
        .image_upload_label {
          &::after {
            content: "";
            display: block;
            width: 40px;
            height: 40px;
            padding: 10px;
            position: absolute;
            top: 10px;
            right: 10px;
            background-color: var(--purple);
            border-radius: 20px;
            background-image: url("../../assets/images/icons/upload-image-icon.svg");
            background-repeat: no-repeat;
            background-size: 20px;
            background-position: center;
            transition-duration: 0.3s;
            cursor: pointer;
            @media (max-width: 767px) {
              right: 10px;
            }
          }
          &:hover:after {
            transform: scale(1.1);
          }
          .image_upload {
            display: none;
          }
        }
      }
      .profile_info_content {
        width: calc(100% - 200px);
        padding-left: 50px;
        @media (max-width: 767px) {
          width: 100%;
          padding: 30px;
          border-top: solid 1px var(--purple);
        }
      }
      h5 {
        font-size: 22px;
        color: var(--mostly-black);
        font-weight: 400;
        strong {
          font-weight: 600;
          min-width: 160px;
          display: inline-block;
          color: var(--mostly-black);
          @media (max-width: 767px) {
            min-width: 120px;
          }
          @media (max-width: 767px) {
            min-width: 100%;
          }
        }
        @media (max-width: 991px) {
          font-size: 18px;
        }
        @media (max-width: 767px) {
          font-size: 16px;
        }
        @media (max-width: 767px) {
          margin-bottom: 20px;
        }
      }
    }
  }
}

.subscription_section {
  padding-top: 50px;
  .subscription_wrapper {
    padding-bottom: 30px;
    border-bottom: 1px solid #888888;
    h2 {
      margin: 0;
      font-size: 28px;
      padding-bottom: 8px;
      margin-bottom: 20px;
      @media (max-width: 767px) {
        font-size: 22px;
      }
      span {
        color: var(--very-light-orange);
      }
    }
    p {
      font-size: 16px;
      margin: 10px 0 0;
      line-height: 1.5;
      font-weight: 400;
      color: var(--mostly-black);
      strong {
        font-weight: 600;
      }
    }
    h3 {
      font-size: 18px;
      font-weight: 500;
      margin: 10px 0 0;
      color: var(--mostly-black);
      span {
        color: var(--dark-gray);
      }
    }
    .subscription_basic_buttons {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 15px;
      flex-wrap: wrap;
    }
    .a {
      background-color: transparent;
      text-decoration: none;
      font-size: 16px;
      font-weight: 500;
      border: 1px solid var(--purple);
      color: var(--purple);
      padding: 6px 20px;
      border-radius: 30px;
      margin-right: 20px;
      @media (max-width: 767px) {
        width: 100%;
        text-align: center;
      }
    }
    .a2 {
      text-decoration: none;
      font-size: 16px;
      font-weight: 500;
      border: 1px solid var(--purple);
      padding: 6px 20px;
      border-radius: 30px;
      background-color: var(--purple);
      color: #fff;
      @media (max-width: 767px) {
        margin-left: 0px;
        margin-top: 10px;
      }
    }

    .recurring_button {
      padding: 0;
      display: unset;
      width: auto;
      font-size: inherit;
      color: var(--bs-link-color);
      text-decoration: underline;
      font-weight: inherit;
      background: transparent;
      margin-left: 4px;
      line-height: inherit;
      margin-top: -5px;
      border: 0;
      &:active,
      &:focus-visible {
        background: transparent;
        color: var(--bs-link-color);
        outline: none;
        box-shadow: none;
      }
    }
  }
}

.security_section {
  padding-top: 30px;
  .security_wrapper {
    h2 {
      margin: 0;
      font-size: 28px;
      padding-bottom: 0px;
      margin-bottom: 10px;
      @media (max-width: 767px) {
        font-size: 22px;
      }
      span {
        color: var(--very-light-orange);
      }
    }
    a {
      color: var(--purple);
      text-decoration: none;
      font-size: 18px;
      font-weight: 500;

      &:hover {
        color: var(--very-light-blue);
      }
    }
    p {
      font-size: 16px;
      margin: 10px 0 0;
      color: var(--mostly-black);
    }
  }
}

.text-capatalize {
  text-transform: capitalize;
}
