.faq_base_display_componet {
	display: flex;
	flex-wrap: wrap;
	&:not(:last-child) {
		margin-bottom: 30px;
	}
	// span {
	// 	display: block;
	// 	font-weight: 500;
	// 	font-size: 18px;
	// 	line-height: 21px;
	// 	color: var(--mostly-black);
	// 	width: 43px;
	// 	@media (max-width: 1199px) {
	// 		font-size: 16px;
	// 		line-height: 19px;
	// 		width: 100%;
	// 	}
	// }
	.faq_base_display_componet_text {
		padding-left: 16px;
		width: calc(100% - 43px);
		@media (max-width: 1199px) {
			padding: 8px 0 0 0;
			width: 100%;
		}
		h4 {
			.sound_icon {
				margin-top: -13px;
				box-shadow: 3px 3px 10px 0px rgba(0, 0, 0,  0.2);
				border-radius: 0.5rem;
				margin-left: 1rem;
			}
			.icons {
				cursor: pointer;
    			margin: 0 20px;
    			display: inline-block;
			}
		}
		.form-control {
			max-width: 220px;
			width: 100%;
			background: var(--white);
			border: 1px solid var(--light-gray);
			border-radius: 10px;
			padding: 10px 20px;
			height: 40px;
			// max-width: 650px;
			display: inline-block;
			margin-right: 13px;
			@media (max-width: 1199px) {
				max-width: 450px;
			}
			&:focus {
				border: 2px solid var(--light-blue);
			}
			&.question_ans_wrong {
				border: 2px solid var(--soft-red);
			}
			&.question_ans_right {
				border: 2px solid var(--strong-cyan);
			}
		}
		h4 {
			font-weight: 400;
			font-size: 18px;
			line-height: 21px;
			color: var(--mostly-black);
			margin-bottom: 15px;
			@media (max-width: 1199px) {
				font-size: 16px;
				line-height: 19px;
			}
			.sound_icon {
				svg {
					height: 50px;
					width: 50px;
					path,
					circle {
						fill: var(--very-pale-orange);
					}
				}
			}
			.info_icon {
				margin-left: 20px;
				cursor: pointer;
			}
		}
		.input-tooltip {
			display: flex;
			align-items: center;
		}
		.svg_container {
			display: flex;
			flex-wrap: wrap;
			> div {
				margin: 0 15px 0 0;
			}
			.sound_icon {
				svg {
					height: 50px;
					width: 50px;
					path,
					circle {
						fill: var(--very-pale-orange);
					}
				}
			}
		}
	}
}
