.error_message {
	padding: 0;
	background: none;
	border: none;
	color: var(--soft-red) !important;
	text-align: left;
	font-size: 16px;
	font-weight: 500;
	@media (max-width: 991px) {
		font-size: 14px;
	}
}
