.signup_success_page {
	display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
	.signup_success_content {
		width: 100%;
		background-color: var(--very-light-blue);
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 30px;
		@media (max-width: 767px) {
			padding: 15px;
		}
		.signup_success_content_wrapeer {
			max-width: 1120px;
			margin: auto;
			text-align: center;
			.signup_image {
				width: 100%;
				svg {
					max-width: 100%;
				}
			}
			.signup_success_title {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 40px;
				margin-bottom: 40px;
				@media (max-width: 767px) {
					margin-top: 20px;
					margin-bottom: 20px;
				}
				h2 {
					font-weight: 600;
					font-size: 50px;
					line-height: 59px;
					color: var(--white);
					margin: 0px;
					@media (max-width: 991px) {
						font-size: 40px;
						line-height: 49px;
					}
					@media (max-width: 767px) {
						font-size: 32px;
						line-height: 41px;
					}
				}
			}
			.signup_success_description {
				h6 {
					font-weight: 500;
					font-size: 22px;
					line-height: 25px;
					color: var(--white);
					text-align: center;
					margin: 0 0 17px;
					@media (max-width: 991px) {
						font-size: 18px;
						line-height: 21px;
					}
					b {
						color: var(--vivid-blue);
						font-weight: 600;
					}
				}
				p {
					font-weight: 500;
					font-size: 18px;
					line-height: 25px;
					color: var(--white);
					text-align: center;
					margin: 0 0 17px;
					@media (max-width: 991px) {
						font-size: 16px;
						line-height: 23px;
					}
					a {
						text-decoration: none;
						color: var(--vivid-blue);
						&:hover {
							text-decoration: underline;
						}
					}
					b {
						color: var(--vivid-blue);
						font-weight: 600;
					}
				}
			}
		}
	}
}
