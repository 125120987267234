.tfq_simple_display {
	.tfq_simple_display_header {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin-bottom: 13px;
		h4 {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			font-weight: 400;
			font-size: 18px;
			line-height: 21px;
			color: var(--mostly-black);
			// margin-bottom: 0;
			width: auto;
			@media (max-width: 767px) {
				font-size: 16px;
				line-height: 19px;
			}
			.info_icon {
				margin-left: 20px;
				cursor: pointer;
			}
			.tfq_simple_DisplaySvg {
				border-radius: 0.5rem;
				margin-left: 1rem;
				margin-top: -13px;
				box-shadow: 3px 3px 10px 0px rgba(0, 0, 0,  0.2);
				svg {
					height: 50px;
					width: 50px;
					rect, path, ellipse {
						fill: var(--very-pale-orange);
					}
				}
			}
		}
		.tfq_simple_display_content {
			width: calc(100% - 43px);
			padding-left: 16px;
			display: flex;
			@media (max-width: 767px) {
				padding: 8px 0 0 0;
				width: 100%;
			}
			h4 {
				align-items: unset;
			}
		}
	}
}

.tfq_simple_display_radio {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 30px;
	padding-left: 59px;
	flex-wrap: wrap;
	@media (max-width: 767px) {
		padding-left: 0;
	}
	.form-check {
		position: relative;
		padding: 0;
		margin: 0 20px 20px 0;
		@media (max-width: 991px) {
			margin: 0 10px 10px 0;
		}
		.form-check-input {
			position: absolute;
			left: 0;
			top: 0;
			margin: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			display: block;
			cursor: pointer;
			+ label {
				position: unset !important;
				&:after {
					display: none !important;
				}
			}
			&:checked {
				+ label {
					background-color: var(--light-blue);
				}
			}
		}
		.form-check-label {
			background: var(--white);
			border: 1px solid var(--light-gray);
			border-radius: 10px;
			width: 137px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			@media (max-width: 767px) {
				width: auto;
				padding: 10px 15px;
			}
			&.active {
				background: var(--light-blue);
			}
			&.question_ans_wrong {
				border: 2px solid var(--soft-red);
			}
			&.question_ans_right {
				border: 2px solid var(--strong-cyan);
			}
		}
	}
}
