.info_icon {
	cursor: pointer;
	margin: 0 20px;
	display: inline-block;
	@media (max-width: 767px) {
		margin: 0 15px;
	}
	&.blub {
		margin: 0 !important;
		svg {
			width: 20px;
			path {
				fill: var(--very-light-blue);
			}
		}
		+ .answere {
			margin-left: 15px;
		}
	}
}

.explanation-modal {
	.modal-body p {
		mark, .mark {
			padding: 0;
		}
	}
}

.resource-video-modal{
	padding: 0;
	.modal-dialog {
		max-width: 900px;
		@media (max-width: 780px) {
			max-width: 500px;
		}
	}
}
