.text-light2 {
  color: #a5a5a5;
}
.ucq_simple_display {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 30px;
  .ucq_simple_display_header {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 13px;
    .info_icon {
      margin-left: 20px;
      cursor: pointer;
    }
    .ucq_heading {
      display: flex;
      padding-left: 16px;
      width: calc(100% - 43px);
      h4 {
        padding: 0px;
      }
    }
  }
  .ucq_base_display_options_radio {
    width: calc(100% - 43px);
    padding-left: 16px;
    @media (max-width: 767px) {
      margin-left: 0;
    }
    label {
      &.form-check-label {
        margin: 0 0 0 15px;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: var(--mostly-black);
        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 17px;
          margin-left: 10px;
        }
      }
    }
    .remove_abcd {
      padding-left: 0;
      .item {
        &:not(:last-child) {
          margin-bottom: 15px;
          margin-right: 1rem;
        }
      }
    }
    ol {
      > div {
        margin-bottom: 1rem;
        margin-right: 2rem;
        @media (max-width: 767px) {
          display: block;
          margin-right: 0;
        }
        li {
          @media (max-width: 767px) {
            margin-bottom: 5px;
          }
          div {
            &:has(img) {
              display: flex;
              align-items: center;
            }
          }
        }
        input {
          &.wrong {
            + label {
              &:after {
                // background: var(--soft-red);
                border: 2px solid var(--soft-red);
                border-color: var(--soft-red);
              }
            }
            &:before {
              // background: var(--soft-red);
              border: 2px solid var(--soft-red);
              border-color: var(--soft-red);
            }
          }
          &.right {
            + label {
              &:after {
                // background: var(--green);
                border: 3px solid var(--strong-cyan);
                border-color: var(--strong-cyan);
              }
            }
            &:before {
              // background: var(--green	);
              border: 2px solid var(--green);
              border-color: var(--green);
            }
          }
        }
      }
      list-style: none;
      padding-left: 26px;
      // display: flex;
      // align-items: flex-start;
    }
  }
  h4 {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--mostly-black);
    padding-left: 0px;
    margin-bottom: 15px;
    width: calc(100% - 43px);
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 17px;
      width: 100%;
      margin-bottom: 0px;
    }
    em {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    p {
      display: inline-block;
    }
  }
  .form-check {
    margin-bottom: 15px;
    .question_ans_right {
      border: 2px solid var(--strong-cyan);
    }
    .question_ans_wrong {
      border: 2px solid var(--soft-red);
    }
  }
}
