.tos-page.resource-page {
    .tos-page-content {
        padding: 60px 0;
    }

    .summary-table {
      border: 1px solid black;
      padding: 10px;
    }

    li.header {
        font-weight: bold;
    }

    img.resource-qn-image {
        width: 100%;
    }
    .text-link {
        cursor: pointer;
        color: var(--vivid-blue);
        font-size: 16px;
    }

    .question_section {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
    }

}