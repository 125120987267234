.timer {
	background-color: aquamarine;
	padding: 5px;
	border-radius: 5px;
	font-weight: bold;
	.danger_time {
		// color: red;
    animation: blink 0.5s alternate ease-in-out infinite;
	}
}

@keyframes blink {
	from {
		opacity: 1;
	}
	to {
		opacity: 0.5;
	}
}
